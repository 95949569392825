/*
 @@@ Desktop Style
*/
@import "../../../Global.module.scss";
@media (min-width: 840px) {
  .download_app_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .intro_oneTwoSmile_icons {
    object-fit: cover;
    cursor: pointer;
    width: 150px;
  }
  .footer_container {
    /* grid-column: 2/4; */
    grid-column: span 4;
    padding: 0px 0 100px 0;
    max-width: 1380px;
    margin: 0 auto;
  }

  .footer_content {
    background: #36434b;
    grid-column: span 4;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2em;
    padding: 100px 100px 0 100px;
    max-width: 100%;
  }
  .countries {
    background: #36434b;
    display: grid;
    grid-column: span 4;
    grid-template-columns: repeat(6, minmax(auto, 1fr));
    align-items: center;
    text-align: center;
    font-size: 1em;
    color: white;
    padding: 40px 100px 100px 100px;
  }
  .country{
    cursor: pointer;
    a {
      text-decoration: none;
      color: white;
    }
  }
  .footer_column {
    display: grid;
    text-align: start;
    align-items: flex-start;
    align-content: flex-start;
    text-transform: uppercase;
  }

  .footer_column_header {
    @include SubTitles();
    font-weight: bold;
    opacity: 0.4;
    color: #ffffff;
    font-size: 14px;
    letter-spacing: 0.8px;
    line-height: 14px;
    padding-bottom: 20px;
    padding-inline-end: 5px;
    text-transform: uppercase;
  }

  .horizontal_line {
    /* padding: 10px 0px; */
    border-bottom: 1px solid #ffffff;
    opacity: 0.4;
    width: 100%;
  }

  .cta_ask_quiestion_container {
    background-color: rgba(61, 205, 168, 0.1);
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
    text-decoration: none;
    padding: 0;
    border: none;
    border-radius: 8px;
    line-height: 42px;
    width: auto;
    padding: 0 1em;
    min-height: 42px;
    margin-top: 20px;
    text-align: center;
    cursor: pointer;
    text-transform: none;
  }
  .cta_ask_quiestion_container a {
    @include SubTitles();
    color: #3dcdba;
    font-weight: bold;
    text-decoration: none;
    text-transform: none;
  }

  .contacts_item {
    display: grid;
    margin-bottom: 13px;
    grid-template-columns: auto 1fr;
    column-gap: 13px;
    align-items: center;
    cursor: pointer;
  }
  .contacts_icon {
    width: 25px;
    height: 25px;
  }

  .desktop_menu_link {
    @include Body();
    font-size: 19px;
    line-height: 28px;
    text-decoration: none;
    color: #ffffff;
    text-transform: none;
    /* word-break: break-word; */
  }

  .footer_links_container {
    display: flex;
    justify-content: space-between;
    padding-inline-end: 5px;
    /* word-break: break-all; */
  }
  .footer_links a {
    @include Body();
    display: block;
    padding-inline-end: 5px;
  }

  .footer_menu_link {
    text-decoration: none;
    color: #ffffff;
    font-size: 15px;
    padding: 5px 0;
    text-transform: none;
    font-weight: 500;
  }

  .footer_resources_countainer {
    display: grid;
    grid-gap: 1em;
  }
  .footer_resources_content {
    @include Body();
    display: flex;
    justify-content: space-between;
  }
  .footer_resource_link {
    font-size: 19px;
    line-height: 28px;
    text-decoration: none;
    color: #ffffff;
    text-transform: none;
  }
  .footer_resource_arrow_en {
    content: "";
    display: block;
    width: 11px;
    height: 11px;
    margin: auto 0;
    border-top: 2px solid #3dcdba;
    border-right: 2px solid #3dcdba;
    border-radius: 3px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .footer_resource_arrow_ar {
    content: "";
    display: block;
    width: 11px;
    height: 11px;
    margin: auto 0;
    border-top: 2px solid #3dcdba;
    border-right: 2px solid #3dcdba;
    border-radius: 3px;
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }
  .resource_horizontal_line {
    padding-bottom: 10px;
    border-top: 1px solid #ffffff;
    opacity: 0.4;
    width: 100%;
  }

  #copyright {
    @include Body();
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    background: #ffffff;
    font-size: 16px;
    line-height: 19px;
    padding: 32px;
    color: #53565a;
    grid-column: span 4;
  }

  #copyright p a {
    font-weight: 700;
    text-decoration: none;
    color: #53565a;
  }

  #copyright a {
    @include Body();
    font-weight: 700;
    text-decoration: none;
    color: #0e1b22;
    margin-bottom: 16px;
  }

  #copyright a:not(:last-child) {
    margin-inline-end: 48px;
  }

  #copyright p {
    @include Body();
    font-size: 16px;
    line-height: 19px;
    opacity: 0.4;
    color: #0e1b22;
  }
}

/*
 @@@ Mobile Style
*/

@media (max-width: 839px) {
  .download_app_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .intro_oneTwoSmile_icons {
    object-fit: cover;
    width: 107px;
    display: block;
  }
  .footer_container {
    background: #36434b;
    grid-column: span 4;
    margin: 0 24px;
  }

  .footer_content {
    background: #36434b;
    grid-column: 2/4;
    display: grid;
    grid-template-columns: 1fr;
    padding: 70px 20px 0 20px;
  }
  .countries {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
    align-items: center;
    text-align: center;
    font-size: 1em;
    color: white;
    padding: 40px 20px 70px 20px;
  }
  .country {
    cursor: pointer;
    a {
      color: white;
      text-decoration: none;
    }
  }

  .footer_column {
    display: grid;
    text-align: start;
    align-items: flex-start;
    align-content: flex-start;
    padding-top: 10px;
  }

  .footer_column_header {
    @include SubTitles();
    justify-self: start;
    padding-bottom: 20px;
    opacity: 0.4;
    color: #ffffff;
    letter-spacing: 0.8px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
  }

  .horizontal_line {
    /* padding: 10px 0px; */
    border-bottom: 1px solid #ffffff;
    opacity: 0.4;
    width: 100%;
  }

  .cta_ask_quiestion_container {
    background-color: rgba(61, 205, 168, 0.1);
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
    text-decoration: none;
    border: none;
    border-radius: 8px;
    line-height: 42px;
    width: auto;
    padding: 0 1em;
    height: 42px;
    margin: 20px 0;
    text-align: center;
    cursor: pointer;
    text-transform: none;
  }
  .cta_ask_quiestion_container a {
    @include SubTitles();
    color: #3dcdba;
    font-weight: bold;
    text-decoration: none;
    text-transform: none;
  }

  .contacts_item {
    margin-bottom: 13px;
    display: grid;
    margin-bottom: 13px;
    grid-template-columns: auto 1fr;
    column-gap: 13px;
    align-items: center;
  }

  .contacts_icon {
    width: 25px;
    height: 25px;
  }

  .desktop_menu_link {
    @include Body();
    font-size: 19px;
    line-height: 28px;
    text-decoration: none;
    color: #ffffff;
    text-transform: none;
    word-break: break-word;
  }
  .footer_links_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
  }

  .footer_links a {
    @include Body();
    display: block;
  }

  .footer_menu_link {
    text-decoration: none;
    color: #ffffff;
    font-size: 15px;
    padding: 5px 0;
    text-transform: none;
    font-weight: 500;
  }

  .footer_resources_countainer {
    display: grid;
    grid-gap: 1em;
  }
  .footer_resources_content {
    @include Body();
    display: flex;
    justify-content: space-between;
  }
  .footer_resource_link {
    font-size: 19px;
    line-height: 28px;
    text-decoration: none;
    color: #ffffff;
    text-transform: none;
  }
  .footer_resource_arrow_en {
    content: "";
    display: block;
    width: 11px;
    height: 11px;
    margin: auto 0;
    border-top: 2px solid #3dcdba;
    border-right: 2px solid #3dcdba;
    border-radius: 3px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .footer_resource_arrow_ar {
    content: "";
    display: block;
    width: 11px;
    height: 11px;
    margin: auto 0;
    border-top: 2px solid #3dcdba;
    border-right: 2px solid #3dcdba;
    border-radius: 3px;
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }
  .resource_horizontal_line {
    padding-bottom: 10px;
    border-top: 1px solid #ffffff;
    opacity: 0.4;
    width: 100%;
  }

  #copyright {
    @include Body();
    background: #ffffff;
    font-size: 16px;
    line-height: 19px;
    padding: 1em;
  }

  #copyright a {
    @include Body();
    display: block;
    font-weight: 700;
    text-decoration: none;
    color: #0e1b22;
    margin-bottom: 16px;
  }

  #copyright p {
    @include Body();
    opacity: 0.4;
    color: #0e1b22;
  }
}
