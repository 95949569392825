@import "../../../Global.module.scss";

.container {
  padding: 20px 24px 72px;
  display: grid;
  grid-template-columns: 1fr 10fr 1fr;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
  }
  .section1_grid {
    display: grid;
    grid-template-columns:  auto auto  ;
    grid-gap: 2em;
    @media (max-width: 839px) {
      grid-template-columns: 1fr;
    }
  }

  .section_1_header {
    @include MainTitles();
    font-size: 40px;
    line-height: 50px;
    max-width: 430px;
    color: #0e1b22;
    margin: 0 auto;
    text-align: start;
    @media (max-width: 839px) {
      width: 100%;
      font-size: 25px;
      line-height: 40px;
      margin-top: 40px;
    }
  }
  .section1_img {
    img {
      max-width: 600px;
      min-height: 100%;
    }

    @media (max-width: 839px) {
      grid-row: 1;
      img {
        width: 250px;
        height: 250px;
        border-radius: 50%;
        margin: 0 auto;
      }
    }
  }
  .section_1_description_1 {
    padding-top: 20px;
    font-size: 18px;
    line-height: 29px;
    color: #0e1b22;
    text-align: start;
    max-width: 430px;
    margin: 0 auto;
    @media (max-width: 839px) {
      width: 100%;
    }
  }

  .section_1_description_2 {
    padding-top: 20px;

    font-size: 18px;
    line-height: 29px;
    color: #0e1b22;
    text-align: start;
    max-width: 430px;
    margin: 0 auto;
    @media (max-width: 839px) {
      width: 100%;
    }
  }

  .get_started_btn {
    font-family: $Open-Sans-Bold;
    display: block;
    background-color: #3dcdba;
    padding: 10px;
    width: 100%;
    height: auto;
    color: white;
    cursor: pointer;
    border-radius: 8px;
    margin: 35px auto;
    font-weight: 500;
    text-decoration: none;
    @media (max-width: 839px) {
      padding: 16px;

      width: 100%;
    }
  }
  .how_it_works_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media (max-width: 839px) {
      grid-template-columns: 1fr;
    }
  }
  .how_it_works {
    text-align: start;
    font-size: 40px;
    line-height: 38px;
    color: #0e1b22;
    // margin: 90px 20px 0 20px;
    margin-top: 50px;
    font-family: $ITC-Avant-Garde-Gothic-Medium;
    @media (max-width: 839px) {
      font-size: 25px;
      line-height: 35px;
      margin: 20px 0 30px 0;
    }
  }

  .featurs {
    display: grid;
    grid-template-columns: auto auto auto;
    align-items: center;
    // justify-content: center;
    margin-top: 50px;
    // grid-gap: 1em;
    @media (max-width: 839px) {
      grid-template-columns: 1fr;
      grid-gap: 1em;
      margin: 0;
    }
  }
  .featur_item {
    display: grid;
    align-items: center;
    justify-content: center;
    img {
      height: 50px;
      width: 50px;
      margin: 0 auto;

      @media (max-width: 839px) {
        height: 40px;
        width: 40px;
      }
    }

    .title {
      font-family: $Open-Sans-Bold;
      font-size: 18px;
      line-height: 29px;
      color: #0e1b22;
      text-align: start;
      padding-top: 20px;
      @media (max-width: 839px) {
        font-size: 16px;
        padding-top: unset;
      }
    }

    @media (max-width: 839px) {
      grid-template-columns: auto 1fr;
      grid-gap: 1em;
    }
  }
}
