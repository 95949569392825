@import "./Global.module.scss";
/* Core Styles */
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
.main-container {
  display: grid;
  grid-template-columns: 1fr 20fr 1fr;
}
/* 
@font-face {
  font-family: "Open Sans";
  font-display: auto;
  src: url("fonts/OpenSans/OpenSans.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
} */

@font-face {
  font-family: "ITC Avant Garde Gothic Medium";
  font-display: swap;
  src: url("fonts/ITC-avant-garde-gothic/ITC Avant Garde Gothic LT Medium.ttf")
    format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "ITC Avant Garde Gothic LT Bold";
  font-display: swap;
  src: url("fonts/ITC-avant-garde-gothic/ITC Avant Garde Gothic LT Bold.ttf")
    format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "ITC Avant Garde CE Gothic Demi";
  font-display: swap;
  src: url("fonts/ITC-avant-garde-gothic/ITC Avant Garde CE Gothic Demi.ttf")
    format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "ITC Avant Garde CE Gothic Demi Light";
  font-display: swap;
  src: url("fonts/ITC-avant-garde-gothic/ITC Avant Garde Gothic LT Extra Light.ttf")
    format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  font-display: swap;
  src: url("fonts/OpenSans/OpenSans.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "DroidNaskhBold";
  font-display: swap;
  src: url("fonts/DroidNaskh-Bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "DroidNaskhRegular";
  font-display: swap;
  src: url("fonts/DroidNaskh-Regular.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
.testFont1 {
  font-family: $ITC-Avant-Garde-Gothic-Medium;
}
.testFont2 {
  font-family: $ITC-Avant-Garde-Gothic-LT-Bold;
}
.testFont3 {
  font-family: $ITC-Avant-Garde-CE-Gothic-Demi;
}
.testFont4 {
  font-family: $ITC-Avant-Garde-CE-Gothic-Demi-Light;
}
.avantGarde {
  font-family: "ITC Avant Garde CE Gothic Demi";
}

.avantGardeLight {
  @include Body();
}

/* Forms */
.form-container {
  display: grid;
  grid-column: 2/4;
  grid-template-columns: 1fr 12fr 1fr;
}

.form-header {
  display: grid;
  grid-column: 1/5;
  grid-template-columns: 1fr auto 1fr;
  margin-top: 2em;
  margin-bottom: 1em;
}

.close-form-container {
  display: grid;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: end;
}

.close-form-container img {
  width: 48px;
  padding: 0.5em;
}

.form {
  grid-column: 2;
  display: grid;
  row-gap: 1em;
}

.submitting-view {
  width: 100%;
  height: 100%;
  position: absolute;
  display: grid;
  align-content: center;
  align-items: center;
  text-align: center;
}

.form-h1 {
  font-size: 1.5em;
  font-weight: bold;
}

.form-h2 {
  font-size: 1.1em;
  font-weight: bold;
}

.form-h3 {
  font-size: 1em;
}

.form-h4 {
  font-size: 0.8em;
}

.form-h1-green {
  @include SubTitles();
  color: #6bcaba;
  font-size: 1.8em;
  font-weight: 800;
  padding: 0.1em 0;
  line-height: 1.2;
}

.form-content {
  display: grid;
  row-gap: 0.5em;
}

.form-input-container {
  border-radius: 0.5em;
}

.form-input {
  border: none;
  background-color: #5c626616;
  border-radius: 0.5em;
  padding: 1em;
  font-size: 1em;
  width: 100%;
}

.formFieldContainer {
  display: grid;
  text-align: start;
}

.sideBySideFields {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1em;
}

.form-phone-container {
  display: grid;
  grid-template-columns: 2fr 3fr;
  column-gap: 1em;
}

.form-splitter {
  border-bottom: #5c626616 dashed 1px;
}

.photo-explanation-container {
  padding-inline-start: 0.5em;
}

.checkbox-explanation-container {
  display: flex;
}

.photo-checkbox-container {
  background-color: #3dcdba16;
  color: #3dcdba;
  font-weight: bold;
  font-size: 0.85em;
  display: flex;
  grid-template-columns: 1fr 6fr;
  text-align: start;
  align-items: center;
}

.optional {
  font-weight: normal;
}

#why-better-span {
  display: none;
}

.photo-upload-button-container {
  position: relative;
  border: 1px solid #5c626616;
  border-radius: 0.5em;
  height: 10em;
  font-size: 1.1em;
  width: 100%;
  overflow: hidden;
  margin: 1em 0;
}

.photo-uploaded-image {
  background-color: #000000;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: -1;
}

.grey-overlay {
  position: absolute;
  background-color: #000;
  width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  opacity: 0.4;
  z-index: 0;
}

.upload-icon-container {
  height: 150px;
  width: 150px;
  position: absolute;
  left: 50%;
  margin-left: -75px;
  top: 50%;
  margin-top: -25px;
  text-align: center;
  color: #ffffff;
  font-size: 0.8em;
  font-weight: bold;
  z-index: 0;
}

.upload-icon {
  width: 27px;
  height: 27px;
  margin: 0 auto;
  opacity: 0.5;
}

.upload-btn-wrapper {
  /* background-color: red; */
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  cursor: pointer;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.submit-form-button {
  background-color: #3dcdba;
  border: none;
  border-radius: 0.5em;
  color: white;
  font-size: 1em;
  font-weight: bold;
  padding: 1em 1em;
  width: 100%;
  margin: 1em 0;
  cursor: pointer;
}

.social-links-form {
  display: flex;
  margin: 0 auto;
}

.social-links-form a {
  display: grid;
  width: 35px;
  height: 35px;
  background: #6bcaba;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-inline-end: 1em;
}

.social-links-form img {
  width: 16px;
  height: 16px;
  object-fit: contain;
}

@media (min-width: 840px) {
  .location_page_img {
    object-fit: cover;
    height: 200px;
  }
  .Banner_Wrapper {
    position: static;
    /* top: 90px; */
    z-index: 22222;
    display: grid;
    grid-template-columns: 1fr auto;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    color: #0e1b22;
    text-align: center;
    background-color: rgb(241, 241, 241);
    /* opacity: 0; */
    width: 100%;
    padding: 5px 25px;
    animation: 1s ease 0s 1 normal forwards running iCqeSY;
  }

  .Banner_Styled_Link a {
    color: #0000ee;
    cursor: pointer;
  }
  .Banner_content {
    @include SubTitles();
    padding: 0px 10px;
  }
  .Banner_CloseButton {
    display: grid;
    justify-content: end;
    align-items: flex-end;
    cursor: pointer;
  }
  .Banner_CloseButton svg {
    width: 20px;
  }
  .form-container {
    display: grid;
    grid-column: 2/4;
    grid-template-columns: 3fr 5fr 3fr;
  }

  #why-better-span {
    display: block;
  }

  .submit-form-button {
    width: auto;
    margin: 1em 20%;
  }

  .trustpilot-experiment {
    position: relative;
    height: 20px;
    margin-top: 0.5em;
    width: 100%;
    border-style: none;
    display: block;
    overflow: hidden;
  }

  section {
    margin: 2.5em auto;
  }

  body {
    @include Body();
    margin: 0;
    background: #ffffff;
    text-align: center;
    font-size: 16px;
    /* font-size: 1.3125em; */
    line-height: 1.5;
    font-weight: 400;
    color: #53565a;
  }

  .container {
    padding: 0 0;
    grid-column: span 4;
    max-width: 1380px;
    margin: 0 auto;
  }

  .cover {
    width: 100%;
    min-height: 45vh;
    grid-column: span 4;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr repeat(2, minmax(auto, 35em)) 1fr;
    background-color: #f9fdfc;
    margin-bottom: 1em;
    margin-top: 0 !important;
  }

  .cover-image {
    width: 100%;
    min-height: 100%;
    background-size: cover !important;
    grid-column: 3/5;
    grid-row: 1/1;
  }

  .cover-text {
    grid-row: 1/1;
    grid-column: 2/3;
    text-align: start;

    padding-inline-end: 3em;
  }

  .cover-text-extra-padding {
    grid-column: 2;
    text-align: start;
    padding-inline-end: 200px;
  }

  .cover-text .white {
    color: #fff !important;
  }
  .cover-text h1 {
    @include MainTitles();
  }
  .cover-text p {
    padding: 0;
  }
  .grid2 {
  }
  .grid {
    display: grid;
    grid-template-columns: 1fr repeat(2, minmax(auto, 35em)) 1fr;
  }

  .grid h2 {
    @include SubTitles();
    margin-top: 20px;
    margin-bottom: 20px;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  .navbar {
    grid-column: 2/4;
    display: grid;
    grid-template-columns: 1fr auto 6fr;
    padding: 0 0;
    align-content: center;
    align-items: center;
    max-width: 1380px;
    margin: 0 auto;
  }

  .small-flag {
    width: 25px;
    /* padding: 0;
    margin: 0; */
    margin-inline-start: 0.2em;
    display: inline-block;
  }

  .mobile-language-change-container {
    display: none;
  }

  .language-change-container {
    text-align: end;
    padding-top: 10px;
    display: flex;
    width: 100%;
    align-content: flex-end;
    align-items: flex-start;
    justify-content: flex-end;
  }

  .language-change-container button {
    border: none;
    background: none;
  }

  .language-change-container button:focus {
    outline: 0;
  }

  .language-change-container button:hover {
    cursor: pointer;
  }

  .language-change-container a {
    @include Body();
    text-decoration: none;
    color: #53565a;
  }

  .desktop-navigation-menu {
    display: grid;
    grid-template-rows: auto auto;
  }

  .menu-items-container {
    display: grid;
    grid-template-columns: repeat(7, auto);
    text-align: end;
    align-items: center;
  }

  .menu-items-container-ae {
    display: grid;
    grid-template-columns: repeat(7, auto);
    text-align: end;
    align-items: center;
  }

  .desktop-menu-link {
    @include Body();
    text-decoration: none;
    color: #53565a;
    font-size: 1.2em;
    font-weight: 400;
    margin: 0;
  }

  .active-menu-link {
    color: #6bcaba !important;
  }

  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
  }

  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #6bcaba;
    min-width: 200px;
    /* box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2); */
    z-index: 1;
    text-align: start;
  }

  /* Links inside the dropdown */
  .dropdown-content a {
    /* color: #53565a; */
    padding: 0.4em 1em;
    text-decoration: none;
    display: block;
    color: #fff;
    font-size: 0.9em;
    /* margin-inline-start: 1em; */
  }

  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {
    background-color: #f1f1f1;
    color: #6bcaba;
  }

  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
  }

  .logo {
    width: 110px;
    height: 50px;
  }

  .logo img {
    width: 100%;
    height: 100%;
  }

  .country-boxes-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 70%;
    margin: auto auto;
    margin-top: 2em;
    column-gap: 1em;
  }

  .country-box {
    padding: 1em;
    display: grid;
    grid-template-columns: 1fr 3fr;
    text-align: start;
    align-content: center;
    align-items: center;
    border-bottom: #cacaca solid 1px;
  }

  .country-box-name {
    @include Body();
    padding: 0 1em;
  }

  .country-box:hover {
    cursor: pointer;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @include MainTitles();
    margin: 0;
    padding: 0;
    line-height: 1.2;
  }

  h1 {
    color: #4b4e56;
    font-size: 2.5em;
    font-weight: 800;
    padding: 0.1em 0;
  }

  .h1-green {
    @include MainTitles();
    color: #6bcaba;
    font-size: 3.6em;
    font-weight: 800;
    padding: 0.1em 0;
    line-height: 1.2;
  }

  .h1-highlight {
    font-size: 1.8em;
    font-weight: lighter !important;
  }

  .h1-white {
    @include SubTitles();
    color: #fff;
    font-size: 3.6em;
    font-weight: 800;
    padding: 0.1em 0;
    line-height: 1.2;
  }

  h2 {
    @include SubTitles();
    color: #6bcaba;
    font-size: 1.8em;
    font-weight: 800;
    padding: 0;
    margin: 0;
  }

  .h2-dark {
    @include SubTitles();
    color: #4b4e56 !important;
    font-size: 1.8em !important;
    font-weight: 700 !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  h3 {
    @include Body();
    color: #53565a;
    font-size: 1.125em;
    font-weight: 200;
    padding: 1em 0 0 0;
    margin-top: 20px;
  }

  h4 {
    color: #6bcaba;
    font-size: 1.3em;
    font-weight: 700;
    margin-top: 15px;
  }

  h5 {
    @include SubTitles();
    color: #4b4e56;
    font-size: 1.4em;
    font-weight: 800;
  }

  .h5-bigger {
    font-size: 1.7em !important;
  }

  h6 {
    color: #ffb81c;
    font-size: 1.3125em;
    font-weight: 800;
    padding: 0.4em 0;
  }

  .sub-h1 {
    @include Body();
    padding: 0.5em 0 !important;
    font-size: 1.2em;
  }

  .sub-h1-black {
    @include Body();
    color: #53565a;
    padding: 1em 0 !important;
    font-size: 1.2em;
  }

  .white .sub-h1 {
    @include Body();
    color: #fff;
  }

  p {
    @include Body();
    margin: 0;
    padding: 0.3em;
    font-size: 1.3125em;
  }

  .span4 {
    grid-column: span 4;
  }

  .upgrade-banner {
    padding-left: 1em;
    padding-right: 1em;
  }

  .callToAction-menu {
    @include SubTitles();
    display: inline-block;
    font-size: 1.125em;
    background: #6bcaba;
    color: #ffffff;
    text-decoration: none;
    padding: 0;
    border: none;
    border-radius: 8px;
    line-height: 40px;
    font-weight: 700;
    width: 180px;
    height: 40px;
    margin: 0.75em 0;
    text-align: center !important;
  }

  .main-cta-container {
    /* background-color: brown;
    width: auto; */
    display: grid;
    width: 18em;
  }

  .main-cta-callout {
    @include SubTitles();
    font-weight: bold;
    font-size: 0.85em;
    text-align: center;
    color: #4b4e56;
    margin-top: 0;
    padding-top: 0;
  }

  .sub-2 {
    @include SubTitles();
    font-size: 0.85em;
    text-align: start;
    color: #4b4e56;
    margin-top: 0;
    padding-top: 0;
    font-family: "italic";
  }
  .crossed-out {
    text-decoration: line-through;
    text-align: center;
  }
  .callToAction-1 {
    @include SubTitles();
    display: inline-block;
    font-size: 1.125em;
    background: #6bcaba;
    color: #ffffff;
    text-decoration: none;
    padding: 0;
    border: none;
    border-radius: 8px;
    line-height: 50px;
    font-weight: 700;
    width: auto;
    padding: 0 1em;
    height: 50px;
    margin: 0.75em 0;
    text-align: center !important;
  }

  .callToAction-1-orange {
    @include SubTitles();
    display: inline-block;
    font-size: 1.125em;
    background: #ffb81c;
    color: #ffffff;
    text-decoration: none;
    padding: 0;
    border: none;
    border-radius: 8px;
    line-height: 50px;
    font-weight: 700;
    width: auto;
    padding: 0 1em;
    height: 50px;
    margin: 0.75em 0;
    text-align: center !important;
  }

  .callToAction-2 {
    @include SubTitles();
    display: inline-block;
    font-size: 1.125em;
    background: #6bcaba;
    color: #ffffff;
    text-decoration: none;
    padding: 0;
    border: none;
    border-radius: 8px;
    line-height: 50px;
    font-weight: 700;
    width: 180px;
    height: 50px;
    margin: 1em 0;
    text-align: center !important;
  }

  .callToAction-3 {
    @include SubTitles();
    display: inline-block;
    font-size: 1.125em;
    background: #fff;
    color: #6bcaba;
    text-decoration: none;
    padding: 0 1em;
    border: none;
    border-radius: 8px;
    line-height: 50px;
    font-weight: 700;
    width: auto;
    height: 50px;
    margin: 1em 0;
  }

  .callToAction-4 {
    @include SubTitles();
    display: inline-block;
    font-size: 1.125em;
    background: #fff;
    color: #e2b973;
    text-decoration: none;
    padding: 0 1em;
    border: none;
    border-radius: 8px;
    line-height: 50px;
    font-weight: 700;
    width: auto;
    height: 50px;
    margin: 1em 0;
  }

  .callToAction-1:hover {
    /* background: #eba50b; */
    color: #ffffff;
  }

  .callToAction-3:hover {
    background: #6bcaba;
    color: #ffffff;
    border-color: #fff;
    border-width: 1px;
    border-style: solid;
  }

  .callToAction-4:hover {
    background: #e2b973;
    color: #ffffff;
    border-color: #fff;
    border-width: 1px;
    border-style: solid;
  }

  .divider {
    display: none;
  }

  .steps-container {
    grid-column: 2/4;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .section-title {
    @include SubTitles();
    grid-column: 2/4;
  }

  .image-and-cta-container {
    grid-column: 2/4;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    align-content: center;
  }

  .image-and-cta-image {
    padding: 4em;
  }

  .image-and-cta-text {
    align-items: center;
    align-content: center;
    text-align: start;
  }

  .image-and-cta-text p {
    padding-inline-start: 0;
  }

  .home-how-it-works-container {
    grid-column: 2/4;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .home-how-it-works-step-container {
    display: grid;
    grid-template-columns: 1fr 3fr;
    margin-bottom: 1em;
  }

  .home-how-it-works-step-text-container h5 {
    text-align: start !important;
    padding-inline-start: 1em !important;
    margin: 0 !important;
  }

  .home-how-it-works-step-text-container p {
    text-align: start !important;
    padding-inline-start: 1em !important;
    margin: 0 !important;
  }

  .home-how-it-works-image {
    padding: 0em;
    padding-bottom: 1em;
  }

  .home-how-it-works-image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .step-card {
    padding: 1.5em;
    /* text-align: justify; */
  }

  .min-height-p-120 {
    min-height: 140px;
  }

  .min-height-p-80 {
    min-height: 80px;
  }

  .step-card p {
    text-align: justify !important;
    font-size: 1em;
  }

  .paragraph-with-title {
    padding: 1.5em;
    /* text-align: justify; */
  }

  .paragraph-with-title p {
    /* text-align: justify !important; */
    font-size: 1em;
    text-align: center;
  }

  .step-image-div {
    /* width: 160px;
    height: 160px; */
    width: 40%;
    height: 40%;
    margin: 15px auto 0;
    padding: 0;
  }

  .step-image-div1 {
    /* width: 160px;
    height: 160px; */
    width: 40%;
    height: 40%;
    margin: 15px auto 0;
    padding: 0;
  }

  .step-image-div img {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }
  .step-image-div1 img {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }

  .banner-div img {
    width: 100%;
    height: 100%;
    /* grid-column: span 4; */
    /* align-items: center; */
  }

  .banner-div {
    width: 100%;
    min-height: 70vh;
    grid-column: span 4;
    /* align-items: center; */
  }

  .banner-div h1 {
    font-size: 2.5em;
  }

  .banner-image {
    display: inline-block !important;
    width: 100%;
    min-height: 70vh;
    grid-column: 2/4;
    position: absolute;
    /* z-index: -1; */
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
  }

  .banner-content {
    grid-column: 2/4;
    text-align: center;
    padding: 0em;
    padding-top: 8vh;
    z-index: 99;
    /* padding-inline-end: 100px; */
  }

  .banner-content h1 {
    font-size: 4vh !important;
  }

  .banner-content p {
    font-size: 3vh !important;
  }

  .banner-1 {
    grid-column: span 4;
    align-items: center;
    width: 100%;
    height: 350px;
  }

  .banner-1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  #advantages-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column: span 4;
    min-height: 200px;
  }

  .advantage-box {
    display: grid;
    color: #fff;
    padding: 0;
    align-content: center;
    align-items: center;
  }

  .advantage-title {
    @include SubTitles();
    font-size: 1.125em;
    padding: 0 !important;
    margin: 0;
  }

  .advantage-text-div {
    min-height: 4em;
  }

  .advantage-text {
    @include Body();
    font-size: 0.875em;
    font-weight: 600;
  }

  .advantage-icon {
    margin: 0 auto;
    padding-top: 0.5em !important;
    width: 50px;
    height: 50px;
  }

  .advantage-icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  #advantage-1 {
    background: #6bcaba;
  }

  #advantage-2 {
    background: #95dcd0;
  }

  #advantage-3 {
    background: #f7d17c;
  }

  #advantage-4 {
    background: #ffb81c;
  }

  .payment-options-container-pricing {
    grid-column: 2/4;
    padding: 0 10em;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-column-gap: 0em;
  }

  .justify-p-desktop {
    text-align: justify;
    hyphens: auto;
  }

  .card {
    display: grid;
    align-items: flex-start;
    align-content: flex-start;
  }

  .card-border {
    border: 1px solid #cbcbcb;
    padding: 1em !important;
  }

  .reviews-container {
    grid-column: 2/4;
  }

  .reviews-horizontal-container {
    margin: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-x: auto;
  }

  .trustpilot-widget {
    grid-column: 2/4;
  }

  .reviews-horizontal-container::-webkit-scrollbar {
    display: none;
  }

  .single-review-slide {
    text-align: start;
    min-width: 400px;
    min-height: 200px;
    margin: 1em;
  }

  .trust-pilot-logo-small {
    height: 1.5em;
    width: auto !important;
  }

  .carousel .slide {
    background-color: #fff !important;
    text-align: start !important;
  }

  .carousel .control-next.control-arrow:before {
    border-left: 8px solid #000 !important;
  }

  .carousel .control-prev.control-arrow:before {
    border-right: 8px solid #000 !important;
  }

  .review-content {
    /* padding: 3em; */
    text-align: start !important;
  }

  .single-review-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .trustpilot-title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .single-review-row {
    width: 100%;
    padding: 0.5em 2.5em;
    text-align: start !important;
  }

  .dark-section {
    background: #fafafa;
  }

  .home-locations-grid {
    padding: 1em 1em;
    display: flex;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 2fr;
    column-gap: 1em;
    row-gap: 1em;
    grid-column: 2/4;
    width: 100%;
    /* margin-top: 2.5em;
    margin-bottom: 3.5em; */
    margin: 0 auto;
    /* align-items: center; */
    /* align-content: space-around, */
    overflow-x: auto;
  }

  .home-locations-grid::-webkit-scrollbar {
    display: none;
  }

  .city-square {
    @include SubTitles();
    min-width: 14vw;
    height: 14vw;
    margin: 0 0.5em;
    display: grid;
    color: #4b4e56;
    align-items: center;
    align-content: center;
    font-size: 1.6em;
    font-weight: 800;
    position: relative;
  }

  .faded-city-image {
    position: absolute;
    min-height: 12vw;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0.5;
    object-fit: cover;
  }

  .cityName {
    min-height: 100%;
  }

  .city-square {
    text-decoration: none;
    color: black;
  }

  .home-payment-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column: 2/4;
  }

  .vertical-divider {
    background: #d3d3d3;
    width: 1px;
  }

  .payment-options-container {
    grid-column: 2/4;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
  }

  .payment-options-container .card {
    padding: 0 4em;
  }

  .payment-plan-image {
    display: grid;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background: #6bcaba;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
  }

  .payment-plan-image img {
    width: 50px;
  }

  .payment-title {
    @include SubTitles();
    font-weight: 700;
    font-size: 1.5em;
  }

  .price-item {
    @include SubTitles();
    color: #4b4e56;
    margin: 0;
    padding: 0;
  }

  .green {
    color: #6bcaba !important;
  }

  .price-item-bigger {
    font-size: 2.5em !important;
  }

  .pricing-container {
    width: fit-content;
    margin: 0 auto;
    text-align: start;
  }

  /* How It Works */

  .how-it-works-steps-section {
    grid-column: 2/4;
    display: grid;
  }

  .how-it-works-step-text {
    padding: 2em;
  }

  .step-card-2 {
    display: grid;
    grid-template-columns: 1fr auto;
    text-align: start;
    padding: 1.5em;
  }

  .step-card-2 p {
    text-align: start;
    padding: 0;
  }

  .step-number {
    @include Body();
    color: #ffb81c;
    font-size: 4.5em;
    font-weight: 800;
    vertical-align: middle;
    padding: 0;
    margin: 0;
    line-height: 2.25em;
    margin-inline-end: 10px;
  }

  .step-number-and-image {
    display: flex;
    margin-bottom: 10px;
  }

  .step-image-div-2 {
    width: 180px;
    height: 180px;
    padding: 0;
  }

  .video-section {
    min-height: 250px;
    text-align: center;
    align-items: center;
    display: grid;
    padding: 4em 12em;
  }
  .video-section-our-doctors {
    min-height: 250px;
    text-align: center;
    align-items: center;
    display: grid;
    padding: 2em 12em;
  }
  .green-section {
    background-color: #f8fdfc;
  }

  .comparison-table {
    grid-column: 2/4;
    padding: 2em;
  }

  .column-icon {
    width: 60px;
    margin: auto auto;
  }

  .comparison-column-title {
    @include SubTitles();
    font-size: 1.25em;
    padding: 0 1em;
  }

  .tick-image {
    width: 21px;
    margin: auto auto;
  }

  .comparison-row {
    display: grid;
    grid-template-columns: 3fr repeat(3, 2fr);
    padding: 1em 0em;
  }

  .two-columns {
    display: grid;
    grid-template-columns: 2fr repeat(2, 2fr) !important;
    column-gap: 3em;
    padding: 1em 2em !important;
    text-align: center;
  }

  .row-border {
    border-bottom: 1px solid black;
  }

  .row-title {
    @include SubTitles();
    text-align: start;
  }
  .cell-text {
    @include SubTitles();
  }
  .orange {
    color: #ffb81c;
  }

  .video-play {
    display: inline-block;
    background: none;
    border: none;
  }

  .content-wrap {
    padding: 0 1em;
  }

  .in-the-box-card {
    display: grid;
    align-content: center;
    align-items: center;
    text-align: justify;
    padding: 3em;
  }

  .in-the-box-card p {
    padding: 0;
  }

  /* Results */

  .testimonials-container {
    display: grid;
    grid-column: 2/4;
    column-gap: 1em;
    row-gap: 1em;
    text-align: center;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    align-content: center;
    margin: 2em 0;
  }

  .new-homepage-testimonials-container {
    display: grid;
    grid-column: 2/4;
    column-gap: 1em;
    row-gap: 1em;
    text-align: center;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    align-content: center;
    margin: 2em 0;
  }

  .testimonial-square {
    text-align: start;
    position: relative;
  }

  .new-homepage-testimonial-square {
    text-align: start;
    position: relative;
  }

  .testimonial-square img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .testimonial-content {
    display: grid;
    align-content: center;
    color: white;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    padding: 1em;
    background-color: rgba(0, 0, 0, 0.7);
    height: 100%;
    width: 100%;
  }

  .testimonial-content:hover {
    /* display: block; */
    opacity: 1;
  }

  .new-homepage-testimonial-content {
    display: grid;
    align-content: center;
    color: white;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    padding: 1em;
    background-color: rgba(0, 0, 0, 0.7);
    height: 100%;
    width: 100%;
  }

  .new-homepage-testimonial-content:hover {
    /* display: block; */
    opacity: 1;
  }

  .read_more_results_button {
    @include SubTitles();
    color: #4b4e56;
    font-size: 1.5em;
    text-decoration: underline;
  }

  .green-quote-section {
    background: #6bcaba;
  }

  .green-quote-div {
    grid-column: 2/4;
    background: #6bcaba;
    display: grid;
    padding: 1em;
    color: #fff;
    margin: 0 4em;
  }

  .text-in-quotes {
    @include Body();
    display: grid;
    text-align: start;
    font-style: italic;
    font-size: 2em;
    grid-template-columns: 1fr 35fr 1fr;
  }

  .quote-icon {
    vertical-align: bottom;
  }

  .quote-person {
    display: grid;
    /* margin-top: 20px; */
    grid-template-columns: 3fr 1fr;
  }

  .long-quote-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column: 2/4;
  }

  .long-quote-card {
    font-style: italic;
    font-size: 1.3125em;
    line-height: 2.5em;
    display: grid;
    text-align: start;
    align-content: flex-start;
    padding: 1em;
  }

  .more-happy-container {
    display: grid;
    grid-column: 2/4;
    grid-template-columns: 1fr 3fr 1fr;
  }

  .more-happy-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 1em auto;
  }

  .more-happy-text {
    @include Body();
    text-align: start;
    // text-justify: inter-word;
    padding: 1em;
  }

  .more-happy-image {
    /* background-color: #fafafa; */
    align-content: center;
    align-items: center;
    display: grid;
  }

  /* Pricing */

  .payment-or-container {
    position: relative;
    height: 100%;
    min-height: 200px;
    display: grid;
  }
  .payment-or {
    @include SubTitles();
    position: relative;
    width: 50px;
    min-height: 50px;
    background: #b5e4dc;
    color: #fff;
    border-radius: 50%;
    align-items: center;
    align-content: center;
    border: 0px solid #d9f1ed;
    font-size: 1em;
    font-weight: 700;
    display: grid;
    padding: 0;
    margin: auto 0px;
  }

  .payment-or-container::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 50%;
    border-left: 1px solid #cacaca;
    transform: translate(-50%);
  }

  .payment-or span {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 0;
    margin: 0;
    border: none;
  }

  .ortho-card {
    grid-column: span 4 !important;
    margin: 2em 0;
    background: #ffb81c;
  }

  .ortho-banner {
    grid-column: span 4 !important;
    height: 350px;
  }

  .ortho-grid {
    display: grid;
    grid-column: 2/4;
    grid-template-columns: 1fr 8fr;
    text-align: start;
    align-content: center;
    align-items: center;
    color: #fff;
    padding: 1em 20%;
    width: calc(100% - 90px);
    margin: auto;
  }

  .ortho-grid p {
    padding-top: 0;
    padding-inline-end: 0;
    padding-inline-start: 3em;
    padding-bottom: 0;
    margin: 0;
    font-size: 1.125em;
  }

  .ortho-grid image {
    width: 100%;
    height: 100%;
    margin: 1em 1em;
    padding: 1em;
    object-fit: contain;
  }

  .price-breakdown-grid {
    display: grid;
    grid-column: 2/4;
    grid-column-gap: 0;
    grid-row-gap: 0;
    margin: 2em auto;
  }

  .price-breakdown-card {
    padding: 0em;
    text-align: center;
    display: grid;
    align-content: center;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    margin: 0 auto;
    padding: 0;
  }

  .dot-container {
    position: relative;
    height: 100%;
    min-height: 200px;
    display: grid;
  }

  .dot {
    border-radius: 50%;
    background: #6bcaba;
    height: 25px;
    width: 25px;
    margin: auto;
  }

  .dot-container::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 50%;
    border-left: 2px dashed #cacaca;
    transform: translate(-50%);
  }

  .price-breakdown-image-div {
    width: 50px;
    height: 50px;
    margin: 5px auto 5px;
    padding: 0;
  }

  .price-breakdown-image-div img {
    margin: 0;
    padding: 0;
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  .price-breakdown-title {
    @include SubTitles();
    font-weight: 700;
    font-size: 1.3125em;
    color: #6bcaba;
  }

  .price-breakdown-title-highlight {
    color: #ffb81c;
  }

  .price-breakdown-description {
    @include Body();
    padding: 1em 0em;
    text-align: start !important;
    grid-column: 4/6;
    font-size: 1.1em;
    padding-inline-end: 4em;
  }

  .price-upgrade-container {
    grid-column: 2/4;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .price-upgrade-text-container {
    text-align: start;
    padding-inline-end: 50px;
    padding: 2em 0 3em 2em;
  }

  .price-upgrade {
    background: #6bcaba;
    color: #fff;
  }

  .price-upgrade h1 {
    color: #fff;
  }

  .price-upgrade {
    background: #6bcaba;
    color: #fff;
  }

  .price-upgrade-call-to-action {
    display: grid;
    justify-items: end;
    align-items: end;
    padding-bottom: 1em;
  }

  .money-back-container {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column: 2/4;
    margin: 4em 16em;
    align-content: center;
    align-items: center;
  }

  .money-back-container_lp_homepage {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column: 2/4;
    margin: 0em 16em;
    align-content: center;
    align-items: center;
  }

  .money-back-container_lp_homepage img {
    width: 140px;
  }

  .money-back-title {
    color: #53565a;
    font: "ITC Avant Garde CE Gothic Demi", "DroidNaskhBold", sans-serif;
    /* font-size: 1em; */
  }

  .money-back-container img {
    width: 140px;
  }

  .money-back-card {
    font-size: 0.7em;
    display: grid;
    text-align: justify;
    hyphens: auto;
    align-content: flex-start;
    padding: 0em 1em;
  }

  /* Support */

  .faq-section {
    background: #fdfdfd;
    padding: 1em;
  }

  .support-upgrade {
    color: #fff;
  }

  .support-upgrade h1 {
    color: #fff;
    margin-top: 30px;
    padding-top: 20px;
  }

  .support-upgrade-call-to-action {
    display: grid;
    text-align: center;
    align-items: start;
  }

  .faq-container {
    grid-column: 2/4;
  }

  .Collapsible {
    @include SubTitles();
    padding: 0.5em;
    width: 100%;
    text-align: start;
  }

  .Collapsible__trigger {
    font-weight: 700;
    font-size: 1.5em;
  }

  .Collapsible .Collapsible__trigger {
    font-weight: 700;
    font-size: 1.3125em !important;
  }

  .Collapsible .Collapsible {
    padding-inline-start: 20px;
  }

  .Collapsible__trigger:hover {
    cursor: pointer;
  }

  /* Privacy Policy */

  .policy-container {
    direction: ltr !important;
    grid-column: 2/4;
    text-align: start;
    padding: 2em 0;
    color: #53565a !important;
  }

  .policy-container p {
    padding: 0;
    margin-bottom: 0.5em;
    color: #53565a !important;
  }

  .policy-container h2 {
    padding: 0;
    color: #53565a !important;
  }

  /* Complete Care */

  .boxes-container {
    grid-column: 2/4;
    margin: 0 6em;
  }

  .box-row-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .box-row-container:nth-of-type(2) .box-image-container {
    grid-column: 2;
    grid-row: 1;
  }

  .box-row-container:nth-of-type(2) .box-text-container {
    grid-column: 1;
    grid-row: 1;
  }

  .box-text-container {
    padding: 1.7em;
    text-align: start;
    display: grid;
    align-content: center;
    align-items: center;
  }
  .box-text-container p {
    @include Body();
  }
  .box-text-title {
    @include SubTitles();
    font-size: 2em;
    text-align: start;
    font-weight: 100;
  }

  .box-text-title b {
    @include SubTitles();
    font-weight: 800 !important;
  }

  .box-image-container {
    padding: 1.7em;
  }

  .box-image-container img {
    object-fit: cover;
    width: 100%;
  }

  /* 404 */
  .not-found-container {
    grid-column: 2/4;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 2em 0;
    align-content: center;
    align-items: center;
  }

  .not-found-container img {
    padding: 6em;
  }

  .not-found-container h1 {
    text-align: start;
  }

  .not-found-container p {
    text-align: start;
  }

  /* Footer */

  .footer {
    grid-column: span 4;
  }

  .footer-content {
    background: #36434b;
    grid-column: span 4;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    padding: 140px 100px 100px;
    width: 1360px;
    max-width: 100%;
    margin: 0 auto;
  }

  .footer-logo-div {
    grid-column: 1/4;
  }

  .footer-logo-div img {
    height: 70px;
    width: 140px;
  }

  .footer-column {
    display: grid;
    text-align: start;
    align-items: flex-start;
    align-content: flex-start;
  }

  .footer-column-header {
    @include SubTitles();
    justify-self: start;
    padding-bottom: 24px;
    opacity: 0.4;
    color: #ffffff;
    letter-spacing: 0.8px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
  }

  .footer-links a {
    display: block;
  }

  .footer .desktop-menu-link {
    @include Body();
    text-decoration: none;
    color: #ffffff;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    margin-bottom: 20px;
  }

  .contacts-item {
    display: grid;
    margin-bottom: 13px;
    grid-template-columns: auto 1fr;
    column-gap: 13px;
    align-items: center;
  }

  .contacts-icon {
    width: 20px;
    height: 20px;
  }

  .footer .contacts-menu-link {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 0;
    white-space: nowrap;
  }

  .bottom-menu a:hover {
    color: #6bcaba;
  }

  .social-links-container {
    display: grid;
    /* align-items: flex-end; */
    /* align-content: flex-end; */
    /* text-align: end; */
  }

  .social-links {
    margin-top: 24px;
    display: flex;
  }

  .social-links a {
    display: grid;
    width: 48px;
    height: 48px;
    background: #4a565d;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    margin-inline-end: 1em;
  }

  .social-links a:hover {
    background-color: #3dcdba;
  }

  .social-links img {
    width: 22px;
    height: 22px;
    object-fit: contain;
  }

  #copyright {
    @include Body();
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    background: #ffffff;
    font-size: 16px;
    line-height: 19px;
    padding: 32px 0;
    color: #53565a;
    grid-column: span 2/4;
  }

  #copyright p a {
    @include Body();
    font-weight: 700;
    text-decoration: none;
    color: #53565a;
  }

  #copyright a {
    @include Body();
    font-weight: 700;
    text-decoration: none;
    color: #0e1b22;
    margin-bottom: 16px;
  }

  #copyright a:not(:last-child) {
    margin-inline-end: 48px;
  }

  #copyright p {
    @include Body();
    font-size: 16px;
    line-height: 19px;
    opacity: 0.4;
    color: #0e1b22;
  }

  .green-strike {
    position: relative;
    z-index: 99;
  }
  .green-strike::before {
    border-bottom: 0.35em solid rgba(107, 202, 186, 0.5);
    content: "";
    left: 0;
    z-index: -1;
    /* margin-top: calc(0.125em / 2 * -1); */
    position: absolute;
    right: 0;
    /* top: 50%; */
    bottom: 2px;
  }

  .why-invisible-aligners-container {
    grid-column: 2/4;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 8em;
  }

  .why-invisible-aligners-container {
    grid-column: 2/4;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 5em;
  }

  .why-invisible-image {
    padding: 1em 6em 2em;
  }

  .why-invisible-title {
    @include SubTitles();
    font-size: 1.1em;
    font-weight: bold;
  }

  .why-invisible-description {
    @include Body();
    font-size: 1em;
  }

  /* Location Style Page */

  .locations-page-container {
    grid-column: 2/4;
  }

  .location-parent-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 1em;
    grid-gap: 1em;
  }

  .child-location {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .location-text {
    text-align: start;
    padding-inline-start: 1em;
    font-size: 1.1em;
  }

  .clinic-name {
    font-weight: bold;
  }

  .locations-book-a-scan-button {
    display: none;
  }

  /* financial Style Page */

  .financial-page-container {
    grid-column: 2/4;
  }
  .payment-steps-container {
    display: grid;
    grid-column: 2/4;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1em;
    padding: 0.5em;
  }
  .payment-step-card {
    padding: 1em;
    text-align: start;
    border: 1px groove black;
  }
  .step-text-card {
    display: grid;
    grid-column-gap: 1em;
    grid-row-gap: 5px;
    justify-content: center;
    align-items: center;
  }
  .card-title {
    font-size: 1em;
    font-weight: bold;
  }
  .payment-plan-button {
    display: grid;
    grid-template-columns: 5fr 1fr;
    text-decoration: none;
    color: #6bcaba;
    font-weight: bold;
  }
  .total-payment-mobile-screen {
    display: none;
  }
  .section-title-modal {
    display: grid;
    grid-template-columns: 1fr 10px 10px;
    text-align: center;
    font-size: 1.3125em;
  }
  .close-payment-modal {
    font-size: 1.5em;
    text-align: start;
  }

  .payment-includes {
    display: grid;
    grid-template-columns: 10px 1fr 1fr 1fr 10px;
    text-align: start;
  }

  .payment-include-image > img {
    object-fit: contain;
    display: inline-block;
    object-position: 80%;
    height: 150px;
  }
  .payment-includes ul {
    list-style: none;
  }

  .payment-includes ul li::before {
    display: inline-block;
    content: "\2022";
    width: 0.5em;
    color: #6bcaba;
    font-weight: bold;
  }
  .payment-includes ul li {
    padding-top: 6px;
  }
  .payment-plan-info {
    display: grid;
    grid-template-columns: 1.5fr 2px 1fr 2px 1.5fr 2px 1fr;
    grid-gap: 1em;
    align-items: flex-start;
    text-align: center;
  }
  .payment-plan-details-parent {
    display: grid;
    grid-row-gap: 0.5em;
    justify-content: center;
  }
  .payment-plan-details-child {
    display: grid;
    grid-gap: 1em;
    text-align: start;
    grid-template-columns: repeat(2, 1fr);
  }
  .vertical-line {
    border-left: 1px solid #000;
    height: 100%;
    min-height: 200px;
  }
  .p-green {
    color: #6bcaba !important;
    font-weight: bold;
  }
  .p-orange {
    color: #ffb81c !important;
    font-weight: bold;
  }

  .cash-payment {
    display: grid;
    grid-template-columns: 10px 1fr 1fr 10px;
    padding: 1em;
    background: #b3b3b3;
    color: #fff;
    text-align: start;
  }

  /* Home Payment Style */

  .home-payment {
    display: grid;
    grid-gap: 1em;
    grid-column: 2/4;
  }
  .home-payment-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
  }
  .monthly-button {
    display: grid;
    justify-content: end;
  }
  .one-time-button {
    display: grid;
    justify-content: start;
  }

  /* Home Testimonials Style */
  .Testimonial-container {
    display: grid;
    grid-template-columns: 2fr 1fr 3fr;
    grid-column: 2/4;
    grid-gap: 1em;
  }

  .testimonial-videos-section {
    display: grid;
    grid-template-rows: 2fr 1fr;
    /* grid-row-gap: 1em; */
    /* padding: 1em; */
    justify-content: end;
    /* grid-template-columns: 1fr 1fr; */
    /* grid-gap: 1em; */
  }
  .first-video {
    grid-row: 1/3;
    position: relative;
  }
  .first-video-info-en {
    @include Body();
    display: grid;
    justify-content: end;
    position: absolute;
    left: 12%;
    bottom: 5%;
  }

  .first-video-info-ar {
    @include Body();
    display: grid;
    justify-content: end;
    position: absolute;
    right: 12%;
    bottom: 5%;
  }
  .second-video {
    display: grid;
    justify-content: end;
    align-items: end;
    position: relative;
    padding: 1em;
  }
  .second-video-info-en {
    @include Body();
    display: grid;
    justify-content: center;
    position: absolute;
    bottom: 13%;
    left: 15%;
  }
  .second-video-info-ar {
    @include Body();
    display: grid;
    justify-content: center;
    position: absolute;
    bottom: 13%;
    right: 15%;
  }

  .third-video {
    display: grid;
    justify-content: start;
    align-items: center;
    position: relative;
  }
  .third-image-info-en {
    @include Body();
    display: grid;
    justify-content: start;
    position: absolute;
    bottom: 35%;
    left: 10%;
  }

  .third-image-info-ar {
    @include Body();
    display: grid;
    justify-content: start;
    position: absolute;
    bottom: 35%;
    right: 10%;
  }
  .round-button {
    box-sizing: border-box;
    display: block;
    width: 40px;
    height: 40px;
    line-height: 20px;
    border: 1px solid #fff;
    border-radius: 50%;
    color: #f5f5f5;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 20px;
    font-weight: bold;
    transition: all 0.3s ease;
  }
  .testimonial-info {
    @include Body();
    display: grid;
    justify-content: center;
    justify-items: start;
    align-items: center;
    align-content: center;
    justify-self: start;
    grid-gap: 1em;
    text-align: start;
  }
  .display-mobile {
    display: none;
  }

  .testimonial-more-results {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
  }
  .testimonial-more-results > span {
    @include SubTitles();
    font-size: 1em;
    font-weight: bold;
  }
  .line-through {
    text-decoration: line-through #ffb81c;
  }

  .close-modal-video {
    font-size: 1.5em;
    text-align: end;
  }
  .testimonial-video-section {
    display: grid;
    justify-content: center;
    align-items: center;
    min-height: 250px;
    text-align: center;
    align-items: center;
    display: grid;
    padding: 4em 12em;
  }

  /* Complete care  Style */
  .complete-care-more-personal > p {
    padding: 1em 0em 0em 0em !important;
  }
  .complete-care-more-personal ul {
    list-style: none;
  }

  .complete-care-more-personal ul li::before {
    display: inline-block;
    content: "\2022";
    width: 0.5em;
    color: #6bcaba;
    font-weight: bold;
  }
  .complete-care-more-personal ul li {
    padding-top: 1em;
    font-size: 1em;
    font-weight: bold;
  }
  .complete-care-steps-container {
    grid-column: 2/4;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
  .complete-care-paragraph-with-title {
    padding: 1.5em;
  }
  .complete-care-paragraph-with-title p {
    font-size: 1em;
    text-align: center;
  }
  .complete-care-get-started {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-gap: 1em;
    padding: 1em;
    background: #f6f6f6;
    font-size: 1em;
  }

  /*  Reviews slider Style */
  .testimonials-slider {
    grid-column: 2/4;
    display: flex;
    position: relative;
    overflow-x: scroll;
    padding: 1em;
  }
  .testimonials-slider::-webkit-scrollbar {
    display: none;
  }
  .testimonials-image-slider {
    position: relative;
    margin-inline-end: 1em;
  }
  .testimonial-play-button-en {
    @include Body();
    position: absolute;
    bottom: 2em;
    left: 2em;
  }
  .testimonial-play-button-ar {
    @include Body();
    position: absolute;
    bottom: 2em;
    right: 2em;
  }
  /* Sitemap  Style */

  .sitemap-content {
    grid-column: 2/4;
    display: grid;
    padding: 2em 0;
    text-align: start;
    justify-content: start;
  }
  .sitemap-content a {
    text-decoration: none;
    color: #000;
  }
  .sitemap-content ul {
    list-style: none;
  }

  .sitemap-content ul li::before {
    display: inline-block;
    content: "\2022";
    width: 0.5em;
    color: #6bcaba;
    font-weight: bold;
  }
  .sitemap-content ul li {
    @include SubTitles();
    padding-top: 6px;
  }
  .not-found-sitemap {
    text-align: start;
  }

  /*  Veneers - choosing between aligners(OneTwoSmile) and veneers Style */
  .aligners-or-veneers-container {
    grid-column: 2/4;
    padding: 1em 0em;
  }

  .aligners-or-veneers {
    grid-column: 2/4;
    padding: 3em 10em;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-column-gap: 0em;
  }
  .aligners-or-veneers-img {
    padding: 1em;
  }
  .space {
    padding-left: 0px;
  }

  /*  About us - styling */

  .paragraph-with-title ul {
    list-style: none;
  }

  .paragraph-with-title ul li::before {
    display: inline-block;
    content: "\2022";
    width: 0.5em;
    color: #6bcaba;
    text-align: start !important;
  }
  .paragraph-with-title ul li {
    padding-top: 10px;
    font-size: 1.3125em;
    text-align: start;
  }
  .list-item-font-size {
    font-size: 1.3125em !important;
    font-weight: normal !important;
  }

  .display-list-items {
    display: grid;
    justify-content: center;
  }
  /*  Ticker bar - styling */
  .ticker-container {
    display: grid;
    grid-column: 2/4;
    grid-template-columns: repeat(4, 1fr);
    padding: 1em;
  }

  /*  Medical-board - styling */
  .medical-advisory-board-container {
    grid-column: 2/4;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 1em;
  }

  .medical-board-child div {
    display: grid;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
  }

  .medical-board-title-font {
    font-size: 1.3125em !important;
    font-weight: bold !important;
    padding: 0 1em !important;
  }
  .medical-board-sub-title-font {
    padding: 0 1em !important;
    font-size: 1em !important;
  }

  /*  OneTwoSmile plus- styling */

  .smile-plus-ideal-title {
    grid-column: 2/4;
    padding: 1.7em;
    text-align: justify;
    display: grid;
    align-content: center;
    align-items: center;
  }

  .smile-plus-ideal-images {
    @include SubTitles();
    grid-column: 2/4;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 0 1em;
    grid-row-gap: 1em;
  }

  .smile-plus-table-container {
    grid-column: 2/4;
    text-align: start;
    display: grid;
  }
  .table-content {
    border-collapse: collapse;
    grid-column: 2/4;
    margin: 0 1.1em;
    font-size: 1em;
  }
  .table-content,
  td {
    font-size: 1.2em !important;
  }
  .table-content,
  td,
  th {
    border: 1px solid black;
    padding: 1em;
  }
  .table-content,
  th {
    @include SubTitles();
  }
  .treatment-price-container {
    grid-column: 2/4;
    padding: 0 1.7em !important;
    text-align: justify;
    display: grid;
  }
  .treatment-price {
    font-size: 1.3em !important;
  }
  .treatment-price ul {
    list-style: none;
  }

  .treatment-price ul li::before {
    display: inline-block;
    content: "\2022";
    width: 0.5em;
    color: #6bcaba;
  }
  .treatment-price ul li {
    @include SubTitles();
    padding-top: 6px;
  }

  .treatment-price-child {
    padding-inline-start: 50px;
    font-size: 1em !important;
    font-weight: normal !important;
  }
  .treatment-price-child ul {
    list-style: none;
  }

  .treatment-price-child ul li::before {
    display: inline-block;
    content: "\2022";
    width: 0.5em;
    color: #6bcaba;
  }
  .treatment-price-child ul li {
    padding-top: 6px;
  }

  /*  RakBank-Offer page- styling */
  .break-line {
    white-space: pre;
  }

  /*  SEO Convert Style */

  .h5 {
    color: #4b4e56 !important;
    font-size: 1.4em;
    font-weight: 800 !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .h3 {
    text-align: start !important;
    padding-inline-start: 1em !important;
  }
  .p {
    @include Body();
    padding: 0.3em 0 !important;
  }

  /* Treatment-plan-page- styling */

  .treatment-palne-container {
    display: grid;
    grid-column: 2/4;
    grid-gap: 1em;
    padding: 1em;
  }
  .label,
  button .loading-label {
    @include Body();
  }
  .Viewer {
    margin: 0px;
    background-color: 0xc0ffff;
    position: relative;
    height: 100%;
    width: 100%;
  }
  #container {
    width: 100%;
    height: 65vh;
    top: 0;
  }
  .setting {
    position: absolute;
    text-align: center;
    width: 100%;
  }
  .position {
    bottom: 80px;
  }
  .position-item {
    position: absolute;
    width: 50px;
    cursor: pointer;
  }
  .position-item img {
    width: 50px;
  }
  .position-item .label {
    color: grey;
  }
  .position-item.active .label {
    color: rgb(89, 181, 172);
  }
  .front {
    left: calc(50% - 120px);
    font-size: 1.3em;
  }
  .upper {
    left: calc(50% - 35px);
    font-size: 1.3em;
  }
  .lower {
    left: calc(50% + 60px);
    font-size: 1.3em;
  }
  .time {
    border: none;
    position: relative;
  }
  .time a:focus {
    outline: 0;
  }

  .time a {
    padding: 5px 40px;
    font-size: 1.3em;
    background-color: grey;
    color: white;
    cursor: pointer;
    margin: 0;
    text-decoration: none;
  }
  .time a.active {
    background-color: rgb(89, 181, 172);
  }

  .link {
    width: 60px;
    top: calc(50vh - 30px);
  }
  .link img {
    width: 100%;
  }
  .link.back {
    left: 10%;
  }
  .link.after {
    right: 10%;
  }

  .setting.link:hover {
    cursor: pointer;
  }

  #loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .loading-label label {
    font-size: 20px;
    font-weight: 900;
    /* margin-right: 10px; */
  }

  #loading-bar {
    /* width: 100%;
    height: 1em;
    border-radius: 0.25em;
    border: 1px solid grey;
    display: inline-flex; */
  }

  #progress {
    /* background-color: #75b800;
    height: inherit;
    border-radius: inherit;
    width: 5%; */
  }

  .close-3dObject-modal {
    font-size: 1.5em;
    text-align: start;
    position: absolute;
    top: 30px;
    right: 40px;
  }

  @keyframes ldio-r3rh32ytal {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .ldio-r3rh32ytal div {
    box-sizing: border-box !important;
  }
  .ldio-r3rh32ytal > div {
    position: absolute;
    width: 144px;
    height: 144px;
    top: 28px;
    left: 28px;
    border-radius: 50%;
    border: 16px solid #000;
    border-color: #6bcaba transparent #6bcaba transparent;
    animation: ldio-r3rh32ytal 2s linear infinite;
  }

  .ldio-r3rh32ytal > div:nth-child(2),
  .ldio-r3rh32ytal > div:nth-child(4) {
    width: 108px;
    height: 108px;
    top: 46px;
    left: 46px;
    animation: ldio-r3rh32ytal 2s linear infinite reverse;
  }
  .ldio-r3rh32ytal > div:nth-child(2) {
    border-color: transparent gray transparent gray;
  }
  .ldio-r3rh32ytal > div:nth-child(3) {
    border-color: transparent;
  }
  .ldio-r3rh32ytal > div:nth-child(3) div {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotate(45deg);
  }
  .ldio-r3rh32ytal > div:nth-child(3) div:before,
  .ldio-r3rh32ytal > div:nth-child(3) div:after {
    content: "";
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    top: -16px;
    left: 48px;
    background: #6bcaba;
    border-radius: 50%;
    box-shadow: 0 128px 0 0 #6bcaba;
  }
  .ldio-r3rh32ytal > div:nth-child(3) div:after {
    left: -16px;
    top: 48px;
    box-shadow: 128px 0 0 0 #6bcaba;
  }

  .ldio-r3rh32ytal > div:nth-child(4) {
    border-color: transparent;
  }
  .ldio-r3rh32ytal > div:nth-child(4) div {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotate(45deg);
  }
  .ldio-r3rh32ytal > div:nth-child(4) div:before,
  .ldio-r3rh32ytal > div:nth-child(4) div:after {
    content: "";
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    top: -16px;
    left: 30px;
    background: gray;
    border-radius: 50%;
    box-shadow: 0 92px 0 0 gray;
  }
  .ldio-r3rh32ytal > div:nth-child(4) div:after {
    left: -16px;
    top: 30px;
    box-shadow: 92px 0 0 0 gray;
  }
  .loadingio-spinner-double-ring-owdtzm8lg5m {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
    /* background: #ffffff; */
  }
  .ldio-r3rh32ytal {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-r3rh32ytal div {
    box-sizing: content-box;
  }
  /* generated by https://loading.io/ */

  .homepageCollapse .Collapsible {
    text-align: center !important;
    padding: 0 !important;
    width: none !important;
  }

  .rc-slider-handle {
    position: absolute;
    width: 26px !important;
    height: 26px !important;
    cursor: pointer;
    cursor: -webkit-grab;
    margin-top: -10px !important;
    cursor: grab;
    border-radius: 50%;
    border: solid 5px #fff !important;
    background-color: #3dcdba !important;
    touch-action: pan-x;
  }

  .rc-slider-track {
    position: absolute;
    left: 0;
    height: 4px;
    border-radius: 6px;
    background-color: #3dcdba !important;
  }
  .rc-slider-rail {
    position: absolute;
    width: 100%;
    background-color: #d6e0eb !important;
    height: 4px;
    border-radius: 1px !important;
  }
  .rc-slider-handle:focus {
    border-color: #57c5f7;
    box-shadow: 0 0 0 5px #3dcdba !important;
    outline: none;
  }

  .rc-slider-tooltip-inner {
    padding: 6px 10px !important;
    min-width: 24px;
    height: 24px !important;
    font-size: 14px !important;
    line-height: 1;
    color: #fff;
    text-align: center;
    text-decoration: none;
    font-weight: bold !important;
    background-color: #3dcdba !important;
    border-radius: 6px;
    box-shadow: 0 0 4px #d9d9d9;
  }
}

/* Our Doctors Page  Started*/
.our-doctor-container {
  width: 300px;
  display: grid;
  grid-template-columns: auto 2fr auto;
  grid-column: 2/4;
  margin: 0 auto;
  align-content: center;
  align-items: center;
}
.our-doctor-container > div > img {
  width: 50px;
  max-height: 50px;
  float: right;
  /* object-fit: cover; */
  border-radius: 8px 0 0 8px;
}

.callToAction-5 {
  @include SubTitles();
  display: inline-block;
  font-size: 1.125em;
  background: #6bcaba;
  color: #ffffff;
  text-decoration: none;
  padding: 0;
  /* border: none;
  border-radius: 8px; */
  line-height: 50px;
  font-weight: 700;
  width: auto;
  padding: 0 1em;
  height: 50px;
  margin: 0.75em 0;
  text-align: center !important;
  border-radius: 0 8px 8px 0;
  border-left: 0.5px solid white;
}
.callToAction-6 {
  @include SubTitles();
  display: inline-block;
  font-size: 0.9em;
  background: #6bcaba;
  color: #ffffff;
  text-decoration: none;
  padding: 0;
  /* border: none;
  border-radius: 8px; */
  line-height: 25px;
  font-weight: 700;
  width: auto;
  padding: 0 1em;
  height: 50px;
  margin: 0.75em 0;
  border-radius: 0;
  margin: 0;
  text-align: center !important;
}

/* Our Doctors Page  Ended */
