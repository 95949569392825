@import "./Global.module.scss";
/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 25px;
  height: 21px;
  right: 1em;
  top: 74px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #53565a;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #6bcaba;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #53565a;
  padding: 1em 1em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #6bcaba;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  @include Body();
  display: inline-block;
  color: white;
  margin-bottom: 0.7em;
  text-decoration: none;
  border: none;
  outline: none;
}

.menu-inner-page-item {
  text-align: start;
}

.bm-item .callToAction-1 {
  @include SubTitles();
  display: inline-block;
  font-size: 1.125em;
  background: #6bcaba;
  color: #ffffff;
  text-decoration: none;
  text-align: center !important;
  padding: 0;
  border: none;
  border-radius: 8px;
  line-height: 50px;
  font-weight: 700;
  /* width: 180px; */
  height: 50px;
  margin: 2em !important;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0);
}

.active-menu-link {
  color: #6bcaba !important;
}

@media (min-width: 840px) {
  .bm-burger-button {
    display: none;
  }
}
