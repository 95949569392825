@import "./Global.module.scss";
/* Core Styles */

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

/* @font-face {
  font-family: "ITC Avant Garde CE Gothic Demi";
  font-display: swap;
  src: url("fonts/ITC-avant-garde-gothic/ITC Avant Garde CE Gothic Demi.ttf")
    format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "ITC Avant Garde CE Gothic Demi Light";
  font-display: swap;
  src: url("fonts/ITC-avant-garde-gothic/ITC Avant Garde Gothic LT Extra Light.ttf")
    format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "DroidNaskhBold";
  font-display: swap;
  src: url("fonts/DroidNaskh-Bold.ttf")
    format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "DroidNaskhRegular";
  font-display: swap;
  src: url("fonts/DroidNaskh-Regular.ttf")
    format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  font-display: auto;
  src: url("fonts/OpenSans/OpenSans.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
} */

body {
  @include Body();
  margin: 0;
  background: #ffffff;
  text-align: center;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  color: #53565a;
}

.span4 {
  padding-bottom: 1em;
}

.cover {
  background-color: #f9fdfc;
}

.cover-image {
  height: 250px;
  background-size: cover !important;
  background-color: #f9fdfc;
  /* background-position-x: 70% !important; */
}

.cover-text {
  padding: 1em;
}
.cover-text h1 {
  @include MainTitles();
}
@media (max-width: 839px) {
  .location_page_img {
    object-fit: cover;
    height: 140px;
  }
  .Banner_Wrapper {
    position: fixed;
    top: 0;
    z-index: 99999;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    color: #0e1b22;
    text-align: start;
    font-size: 14px;
    background-color: rgb(241, 241, 241);
    padding: 5px 25px;
    height: 70px;
    width: 100%;
    animation: 1s ease 0s 1 normal forwards running iCqeSY;
  }
  .Banner_Styled_Link a {
    color: #0000ee;
  }

  .Banner_content {
    @include SubTitles();
    padding: 0px 10px;
  }

  .Banner_CloseButton svg {
    width: 20px;
  }

  .inner-page-container {
    padding-top: 108px;
  }

  .inner-page-container-with-banner {
    padding-top: 154px;
  }

  .trustpilot-experiment {
    position: relative;
    height: 20px;
    width: 100%;
    border-style: none;
    display: block;
    overflow: hidden;
    margin-top: 0.5em;
  }
  .mobile-container {
    padding: 0 24px;
  }
  .grid p {
    padding: 0em 1em 1em 1em;
  }

  .grid h2 {
    @include SubTitles();
    margin-top: 20px;
    margin-bottom: 20px;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  /* .navbar {
    display: grid;
    grid-template-columns: 1fr auto 3fr;
    padding: 0.5em 0;
    direction: ltr !important;
  } */

  .small-flag {
    width: 25px;
    /* padding: 0;
    margin: 0; */
    margin-inline-start: 0.2em;
    display: inline-block;
  }

  .mobile-language-change-container {
    text-align: end;
    padding-top: 10px;
    display: grid;

    /* width: 100%; */
    align-content: flex-end;
    align-items: flex-start;
    justify-content: flex-end;
    margin-inline-end: 4em;
  }

  .mobile-language-country-container {
    display: flex;
    justify-content: flex-end;
  }

  .mobile-language-change-container button {
    border: none;
    background: none;
  }

  .mobile-language-change-container button:focus {
    outline: 0;
  }

  .mobile-language-change-container button:hover {
    cursor: pointer;
  }

  .mobile-language-change-container a {
    @include SubTitles();
    text-decoration: none;
    color: #53565a;
  }

  .desktop-navigation-menu {
    display: none;
  }

  .logo {
    width: 110px;
    height: 50px;
  }

  .logo img {
    width: 100%;
    height: 100%;
  }

  .country-modal-view {
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 60vw;
    height: auto;
    border: none;
    /* background-color:#fafafa; */
    background-color: red;
  }

  .country-boxes-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    margin: auto auto;
    margin-top: 2em;
    column-gap: 1em;
  }

  .country-box {
    padding: 1em;
    display: grid;
    grid-template-columns: 1fr 3fr;
    text-align: start;
    align-content: center;
    align-items: center;
    border-bottom: #cacaca solid 1px;
  }

  .country-box-name {
    @include Body();
    padding: 0 1em;
  }

  .country-box:hover {
    cursor: pointer;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @include MainTitles();
    margin: 0;
    padding: 0;
    line-height: 1.2;
  }

  h1 {
    color: #4b4e56;
    font-size: 2.3em;
    font-weight: 800;
    padding: 0.4em 0;
  }

  .h1-green {
    @include MainTitles();
    color: #6bcaba;
    font-size: 1.75em;
    font-weight: 800;
    padding: 0.4em 0;
    line-height: 1.2;
  }

  .h1-highlight {
    font-size: 1.6em;
    font-weight: lighter !important;
  }

  h2 {
    @include SubTitles();
    color: #6bcaba;
    font-size: 1.7em;
    font-weight: 700;
    padding: 0;
    margin: 0;
  }

  .h2-dark {
    @include SubTitles();
    color: #4b4e56 !important;
    font-size: 1.7em !important;
    font-weight: 700 !important;
    margin: 0 !important;
  }

  h3 {
    @include Body();
    color: #53565a;
    font-size: 1.125em;
    font-weight: 200;
    padding: 1em 0 0 0;
    margin-top: 20px;
  }

  h4 {
    color: #6bcaba;
    font-size: 1.3em;
    font-weight: 700;
    margin-top: 15px;
  }

  h5 {
    color: #4b4e56;
    font-size: 1.3em;
    font-weight: 700;
  }

  h6 {
    color: #ffb81c;
    font-size: 1.3125em;
    font-weight: 800;
    padding: 0.4em 0;
  }

  .sub-h1 {
    @include Body();
    font-size: 1.3125em;
    text-align: start;
  }

  p {
    @include Body();
    margin: 0;
    padding: 0.3em;
    font-size: 1em;
  }

  .upgrade-banner {
    padding-left: 1em;
    padding-right: 1em;
  }

  .main-cta-callout {
    @include SubTitles();
    font-weight: bold;
    font-size: 0.8em;
    text-align: center;
    color: #4b4e56;
    margin-top: 0;
    padding-top: 0;
  }

  .sub-2 {
    @include SubTitles();
    font-size: 0.8em;
    text-align: center;
    color: #4b4e56;
    margin-top: 0;
    padding-top: 0;
    font-family: "italic";
  }

  .crossed-out {
    text-decoration: line-through;
    text-align: center;
  }

  .callToAction-1 {
    @include SubTitles();
    display: inline-block;
    font-size: 1.125em;
    background: #6bcaba;
    color: #ffffff;
    text-decoration: none;
    padding: 0 1em;
    border: none;
    border-radius: 8px;
    line-height: 50px;
    font-weight: 700;
    width: auto;
    height: 50px;
    margin: 0;
    margin-bottom: 0.5em;
  }

  .callToAction-1-navbar-mobile {
    @include SubTitles();
    display: inline-block;
    font-size: 1em;
    background: #6bcaba;
    color: #ffffff;
    text-decoration: none;
    padding: 0em 1em;
    border: none;
    border-radius: 8px;
    line-height: 35px;
    font-weight: 700;
    height: 35px;
    margin: 0;
    margin-bottom: 0.5em;
  }

  .callToAction-2 {
    @include SubTitles();
    display: inline-block;
    font-size: 1.125em;
    background: #6bcaba;
    color: #ffffff;
    text-decoration: none;
    padding: 0;
    border: none;
    border-radius: 8px;
    line-height: 50px;
    font-weight: 700;
    width: 180px;
    height: 50px;
    margin: 1em 0;
  }

  .callToAction-3 {
    @include SubTitles();
    display: inline-block;
    font-size: 1.125em;
    background: #fff;
    color: #6bcaba;
    text-decoration: none;
    padding: 0 1em;
    border: none;
    border-radius: 8px;
    line-height: 50px;
    font-weight: 700;
    width: auto;
    height: 50px;
    margin: 1em 0;
  }

  .callToAction-4 {
    @include SubTitles();
    display: inline-block;
    font-size: 1.125em;
    background: #fff;
    color: #e2b973;
    text-decoration: none;
    padding: 0 1em;
    border: none;
    border-radius: 8px;
    line-height: 50px;
    font-weight: 700;
    width: auto;
    height: 50px;
    margin: 1em 0;
  }

  .callToAction-1:hover {
    background: #48c4af;
    color: #ffffff;
  }

  .callToAction-3:hover {
    background: #6bcaba;
    color: #ffffff;
    border-color: #fff;
    border-width: 1px;
    border-style: solid;
  }

  .callToAction-4:hover {
    background: #e2b973;
    color: #ffffff;
    border-color: #fff;
    border-width: 1px;
    border-style: solid;
  }

  .divider {
    width: 100%;
    height: 10px;
  }

  .step-card {
    display: grid;
    grid-template-columns: 1fr 3fr;
    padding: 1em 1.5em;
    text-align: start;
  }

  .paragraph-with-title {
    padding: 0.8em 3em;
    /* text-align: start; */
  }

  .paragraph-with-title .step-image-div {
    padding: 0em 6em;
    /* text-align: start; */
  }
  .step-image-div1 {
    padding: 1em 40px;
  }
  .paragraph-with-title p {
    text-align: start !important;
    hyphens: auto;
    font-size: 1em;
  }

  .step-image-div {
    width: 100%;
    /* height: 180px; */
    margin: 0 auto;
    padding: 0;
    grid-column: 1;
    grid-row: 1;
  }

  .step-text-div {
    display: grid;
    grid-column: 2/4;
    grid-column-gap: 1em;
    grid-row-gap: 5px;
    padding: 0 1em;
  }

  .step-image-div img {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .banner-1 {
    width: 100%;
    height: 250px;
  }

  .banner-1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .banner-div {
    width: 100%;
    /* min-height: 250px; */
  }

  .banner-image {
    /* width: 100%;
    min-height: 250px;
    background-size: cover !important;
    background-position-x: 70% !important; */
    /* display: none; */
  }

  .tp-widget-rating {
    font-size: 10em !important;
    color: red !important;
  }

  .reviews-container {
    padding: 1em;
  }

  .reviews-horizontal-container {
    margin: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-x: auto;
  }

  .reviews-horizontal-container::-webkit-scrollbar {
    display: none;
  }

  .single-review-slide {
    text-align: start;
    min-width: 400px;
    min-height: 200px;
    margin: 1em;
  }

  .trust-pilot-logo-small {
    height: 1.5em;
    /* padding-top: 1em; */
    /* margin-top: 2em; */
    width: auto !important;
  }

  .carousel .slide {
    background-color: #ecf0f4 !important;
    text-align: start !important;
  }

  .carousel .control-next.control-arrow:before {
    border-left: 8px solid #000 !important;
  }

  .carousel .control-prev.control-arrow:before {
    border-right: 8px solid #000 !important;
  }

  .review-image {
    padding: 0.5em 1em;
  }

  .review-content {
    padding: 0.5em;
    text-align: start !important;
  }

  .single-review-container {
    display: grid;
    grid-template-columns: 1fr 6fr;
  }

  .trustpilot-title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .single-review-row {
    padding: 0.5em 2.5em;
    text-align: start !important;
  }

  .dark-section {
    background: #fafafa;
  }

  .section-title {
    @include SubTitles();
    padding-top: 1em;
    padding-left: 1.5em;
    padding-right: 1.5em;
  }

  .image-and-cta-container {
    display: grid;
  }

  .image-and-cta-text {
    align-items: flex-start;
    align-content: flex-start;
    text-align: center;
    grid-row: 2;
  }

  .image-and-cta-image {
    grid-row: 1;
    padding: 0 4em;
    margin-top: 3em;
  }

  .home-payment-grid {
    display: grid;
    grid-template-columns: 1fr 6fr 1fr;
    grid-column: 2/4;
  }

  .pricing-container {
    width: fit-content;
    margin: 0 auto;
    text-align: start;
  }

  .home-how-it-works-container {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0 2em;
  }

  .home-how-it-works-step-container {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-row: 2;
    margin-bottom: 1em;
  }

  .home-how-it-works-step-text-container h5 {
    text-align: start !important;
    padding-inline-start: 1em !important;
    margin: 0 !important;
  }

  .home-how-it-works-step-text-container p {
    text-align: start !important;
    padding-inline-start: 1em !important;
    margin: 0 !important;
  }

  .home-how-it-works-image {
    grid-row: 1;
    padding: 0em;
    padding-bottom: 1em;
  }

  .home-how-it-works-image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .home-locations-grid {
    padding: 1em 1em;
    display: flex;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 2fr;
    column-gap: 1em;
    row-gap: 1em;
    /* margin-top: 2.5em;
    margin-bottom: 3.5em; */
    margin: 0 auto;
    /* align-items: center; */
    align-content: space-around;
    overflow-x: auto;
  }

  .home-locations-grid::-webkit-scrollbar {
    display: none;
  }

  .city-square {
    min-width: 30vw;
    height: 30vw;
    margin: 0 0.5em;
    display: grid;
    align-items: center;
    align-content: center;
    font-size: 1.2em;
    font-weight: 800;
    position: relative;
  }

  .faded-city-image {
    position: absolute;
    min-height: 12vw;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0.5;
    object-fit: cover;
  }

  .cityName {
    min-height: 100%;
  }

  .city-square {
    text-decoration: none;
    color: black;
  }

  #advantages-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .advantage-box {
    display: grid;
    color: #ffffff;
    padding: 0;
    align-content: center;
    align-items: center;
  }

  .advantage-title {
    @include SubTitles();
    font-size: 1.125em;
    padding: 0 !important;
    margin: 0;
  }

  .advantage-text {
    @include Body();
    font-size: 0.875em;
    font-weight: 600;
  }

  .advantage-icon {
    margin: 0 auto;
    padding-top: 0.5em !important;
    width: 50px;
    height: 50px;
  }

  .advantage-icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  #advantage-1 {
    background: #6bcaba;
  }

  #advantage-2 {
    background: #95dcd0;
  }

  #advantage-3 {
    background: #f7d17c;
  }

  #advantage-4 {
    background: #ffb81c;
  }

  .card {
    display: grid;
  }

  .payment-plan-image {
    display: grid;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background: #6bcaba;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
  }

  .payment-plan-image img {
    width: 50px;
  }

  .payment-title {
    @include SubTitles();
    font-weight: 700;
    font-size: 1.5em;
    margin: 0;
    padding: 0;
    margin: 0 !important;
    padding: 0 !important;
  }

  .price-item {
    @include SubTitles();
    color: #4b4e56;
    font-size: 1.5em;
    margin: 0 !important;
    padding: 0 !important;
  }

  .green {
    color: #6bcaba !important;
  }

  /* How It Works */

  .step-card-2 {
    display: grid;
    text-align: start;
    padding: 1.5em;
  }

  .step-card-2 p {
    text-align: start;
    padding: 0;
  }

  .step-number {
    @include Body();
    color: #ffb81c;
    font-size: 4.5em;
    font-weight: 800;
    vertical-align: middle;
    padding: 0;
    margin: 0;
    line-height: 2.25em;
    margin-inline-end: 10px;
  }

  .step-number-and-image {
    display: flex;
    margin-bottom: 10px;
  }

  .step-image-div-2 {
    width: 180px;
    height: 180px;
    padding: 0;
  }

  .video-section {
    min-height: 250px;
    text-align: center;
    align-items: center;
    display: grid;
    padding: 1em 1em;
  }
  .video-section-our-doctors {
    min-height: 250px;
    text-align: center;
    align-items: center;
    display: grid;
    padding: 0.5em 1em;
  }
  .youtube-video {
    width: 100%;
  }

  .green-section {
    background-color: #f8fdfc;
  }

  .comparison-table {
    padding: 1.5em;
  }

  .comparison-cell {
    text-align: center;
    align-items: center;
  }

  .column-icon {
    width: 40px;
    margin: auto auto;
  }

  .comparison-column-title {
    @include SubTitles();
    font-size: 0.7em;
    padding: 0;
  }

  .tick-image {
    width: 14px;
    margin: auto auto;
  }

  .comparison-row {
    display: grid;
    align-content: center;
    align-items: center;
    grid-template-columns: 1fr repeat(2, 2fr);
    padding: 1em 0em;
  }

  .two-columns {
    column-gap: 2em;
  }

  .row-border {
    border-bottom: 1px solid black;
  }

  .row-title {
    @include SubTitles();
    text-align: start;
    font-size: 1em;
  }
  .cell-text {
    @include SubTitles();
  }
  .traditional-clear {
    display: none;
    /* visibility: hidden; */
  }

  .orange {
    color: #ffb81c;
  }

  .video-play {
    display: inline-block;
    background: none;
    border: none;
  }

  .content-wrap {
    padding: 0 1em;
  }

  .in-the-box-card {
    /* text-align: start; */
    padding: 0em 1.5em;
  }

  .in-the-box-card p {
    text-align: center;
    /* padding: 1em 1.5em; */
  }

  /* Results */

  .testimonials-container {
    /* display: grid;
    padding: 0 1em;
    column-gap: 0.5em;
    row-gap: 2em;
    text-align: center;
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    align-content: start;
    margin: 1em auto; */
    display: flex;
    overflow-x: auto;
  }

  .testimonials-container::-webkit-scrollbar {
    display: none;
  }

  .testimonial-square {
    text-align: start;
    min-width: 300px;
    margin: 0.25em;
    padding: 0.25em;
  }

  // .testimonial-content {
  //   text-align: start;
  //   display: grid;
  //   align-content: flex-start;
  // }

  // .testimonial-content p {
  //   padding: 0;
  // }

  .read_more_results_button {
    @include SubTitles();
    color: #4b4e56;
    font-size: 1.2em;
    text-decoration: underline;
  }

  .green-quote-section {
    background: #6bcaba;
    display: grid;
    padding: 1em;
    color: #fff;
  }

  .text-in-quotes {
    @include Body();
    display: grid;
    text-align: start;
    font-style: italic;
    font-size: 1em;
    grid-template-columns: 1fr 26fr 1fr;
  }

  .quote-icon {
    vertical-align: bottom;
  }

  .quote-person {
    display: grid;
    /* margin-top: 20px; */
    grid-template-columns: 1fr 1fr;
  }

  .long-quote-card {
    font-style: italic;
    font-size: 1em;
    display: grid;
    text-align: start;
    padding: 1em;
  }

  .more-happy-container {
    display: grid;
    grid-column: 2/4;
    grid-template-columns: 1fr 10fr 1fr;
  }

  .more-happy-row {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin: 1em auto;
  }

  .more-happy-text {
    @include Body();
    text-align: start;
    padding: 1em 0;
  }

  .more-happy-text p {
    padding: 0 0 1em 0;
  }

  .more-happy-image {
    background-color: #fafafa;
  }

  /* Pricing */

  .ortho-banner {
    height: 250px;
  }

  .payment-options-container-pricing {
    grid-column: 2/4;
    padding: 0 4em;
    display: grid;
    /* grid-template-columns: 1fr auto 1fr;  */
    grid-column-gap: 0em;
  }

  .justify-p-desktop {
    text-align: start;
  }

  .payment-or-container {
    position: relative;
    width: 100%;
    display: grid;
    margin: 1em auto;
  }

  .payment-or {
    @include SubTitles();
    position: relative;
    width: 50px;
    min-height: 50px;
    background: #b5e4dc;
    color: #fff;
    border-radius: 50%;
    align-items: center;
    align-content: center;
    border: 0px solid #d9f1ed;
    font-size: 1em;
    font-weight: 700;
    display: grid;
    padding: 0;
    margin: auto auto;
  }

  .payment-or-container::after {
    content: "";
    position: absolute;
    z-index: -1;
    height: 1px;
    width: 50%;
    margin: 0 auto;
    top: 50%;
    bottom: 0;
    left: 50%;
    /* border-right: 1px solid #cacaca;
    border-top: 1px solid #cacaca; */
    background-color: #cacaca;
    transform: translate(-50%);
  }

  .payment-or span {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 0;
    margin: 0;
    border: none;
  }

  .ortho-grid {
    display: grid;
    grid-template-columns: 1fr 8fr;
    text-align: start;
    align-content: center;
    align-items: center;
    background: #ffb81c;
    color: #fff;
    padding: 1em;
  }

  .ortho-grid p {
    padding-top: 0;
    padding-right: 1em;
    padding-left: 1em;
    padding-bottom: 0;
    margin: 0;
  }

  .ortho-grid image {
    width: 100%;
    height: 100%;
    margin: 1em 1em;
    padding: 1em;
    object-fit: contain;
  }

  .dot-container {
    display: none;
  }

  .price-breakdown-card {
    padding: 0em 4em;
  }

  .price-breakdown-description {
    @include Body();
    text-align: start;
    hyphens: auto;
  }

  .price-breakdown-image-div {
    width: 50px;
    height: 50px;
    margin: 5px auto 5px;
    padding: 0;
  }

  .price-breakdown-image-div img {
    margin: 0;
    padding: 0;
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  .price-breakdown-title {
    @include SubTitles();
    font-weight: 700;
    font-size: 1.0625em;
    color: #6bcaba;
  }

  .price-breakdown-title-highlight {
    color: #ffb81c;
  }

  .price-upgrade {
    background: #6bcaba;
    color: #fff;
  }

  .price-upgrade h1 {
    color: #fff;
    margin-top: 30px;
    padding-top: 20px;
  }

  .money-back-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-column: 2/4;
    margin: 1em 2em;
    align-content: center;
    align-items: center;
  }
  .money-back-container_lp_homepage {
    display: grid;
    grid-template-columns: 1fr;
    grid-column: 2/4;
    margin: 1em 2em;
    align-content: center;
    align-items: center;
  }
  .money-back-title {
    color: #53565a;
    font: "ITC Avant Garde CE Gothic Demi", "DroidNaskhBold", sans-serif;
    /* font-size: 1em; */
  }

  .money-back-container_lp_homepage img {
    width: 110px;
    margin: 1em auto;
  }
  .money-back-container img {
    width: 110px;
    margin: 1em auto;
  }

  .money-back-card {
    font-size: 0.85em;
    display: grid;
    align-content: flex-start;
    padding: 0em 0em;
  }

  .money-back-card p {
    padding: 0 1em !important;
  }

  /* Support */

  .faq-section {
    background: #fdfdfd;
    padding: 1em;
  }

  .support-upgrade {
    background: #e2b973;
    color: #fff;
  }

  .support-upgrade h1 {
    color: #fff;
    margin-top: 30px;
    padding-top: 20px;
  }

  .Collapsible {
    @include SubTitles();
    padding: 0.5em;
    width: 100%;
    text-align: start;
  }

  .Collapsible__trigger {
    font-weight: 700;
  }

  .Collapsible .Collapsible {
    padding-inline-start: 20px;
  }

  .Collapsible__trigger:hover {
    cursor: pointer;
  }

  /* Privacy Policy */

  .policy-container {
    direction: ltr !important;
    grid-column: 2/4;
    text-align: start;
    padding: 2em 1em;
    color: #53565a !important;
  }

  .policy-container p {
    padding: 0;
    margin-bottom: 0.5em;
    color: #53565a !important;
  }

  .policy-container h2 {
    padding: 0;
    color: #53565a !important;
  }

  /* Complete Care */

  .boxes-container {
    grid-column: 2/4;
    margin: 0 3em;
  }

  .box-row-container {
    display: grid;
    margin: 1em 0em;
    /* grid-template-columns: repeat(2, 1fr); */
  }

  .box-text-container {
    /* padding: 0em 1.5em; */
    text-align: start;
    display: grid;
    grid-row: 1;
    grid-column: 1;
    /* align-content: center;
    align-items: center; */
  }

  .box-text-title {
    @include SubTitles();
    font-size: 2em;
    text-align: start;
    font-weight: 100;
    padding: 0 !important;
    margin-bottom: 0.5em;
  }

  .box-text-container p {
    @include Body();
    padding: 0 !important;
  }

  .box-text-title b {
    @include SubTitles();
    padding: 0;
    font-weight: 800 !important;
  }

  .box-image-container {
    padding: 1em 0;
    grid-row: 2;
    grid-column: 1;
  }

  .box-image-container img {
    object-fit: cover;
    width: 100%;
  }

  /* 404 */
  .not-found-container {
    grid-column: 2/4;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    padding: 2em 0;
    align-content: center;
    align-items: center;
  }

  .not-found-container img {
    padding: 1em 6em;
  }

  /* Footer */

  .footer {
    background: #36434b;
    grid-column: span 4;
    margin: 0 24px;
  }

  .footer-content {
    grid-column: 2/4;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 70px 20px;
  }

  .footer-logo-div {
    grid-column: span 2;
    justify-self: center;
  }

  .footer-logo-div img {
    height: 70px;
    width: 140px;
    margin: 0 auto;
  }

  .footer-column {
    display: grid;
    text-align: start;
    align-items: flex-start;
    align-content: flex-start;
    grid-column: 1/3;
  }

  .footer-column-header {
    @include Body();
    justify-self: start;
    padding-bottom: 24px;
    opacity: 0.4;
    color: #ffffff;
    letter-spacing: 0.8px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
  }

  .footer-links {
    display: flex;
  }

  .links-column {
    flex-basis: 50%;
  }

  .footer-links a {
    display: block;
  }

  .bottom-menu a:hover {
    color: #6bcaba;
  }

  .desktop-menu-link {
    @include Body();
    text-decoration: none;
    color: #ffffff;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    /* padding: 0 0.5em; */
    margin-bottom: 20px;
  }

  .contacts-menu-link {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 0;
    white-space: nowrap;
  }

  .contacts-item {
    margin-bottom: 13px;
    display: grid;
    margin-bottom: 13px;
    grid-template-columns: auto 1fr;
    column-gap: 13px;
    align-items: center;
  }

  .contacts-icon {
    width: 20px;
    height: 20px;
  }

  .social-links {
    display: flex;
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .social-links a {
    display: grid;
    width: 48px;
    height: 48px;
    background: #4a565d;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
  }

  .social-links a:not(:last-child) {
    margin-inline-end: 1em;
  }

  .social-links a:hover {
    background-color: #3dcdba;
  }

  .social-links img {
    width: 22px;
    height: 22px;
    object-fit: contain;
  }

  #contact-us {
    padding: 1em;
  }

  #contact-us h3 {
    @include Body();
    margin: 0;
    padding: 0;
    font-size: 1.1875em;
  }

  #contact-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  #contact-info p a {
    text-decoration: none;
    color: #53565a;
  }

  #copyright {
    @include Body();
    background: #ffffff;
    font-size: 16px;
    line-height: 19px;
    padding: 1em;
  }

  #copyright a {
    @include Body();
    display: block;
    font-weight: 700;
    text-decoration: none;
    color: #0e1b22;
    margin-bottom: 16px;
  }

  #copyright p {
    @include Body();
    opacity: 0.4;
    color: #0e1b22;
  }

  .green-strike {
    position: relative;
    z-index: 99;
  }
  .green-strike::before {
    border-bottom: 0.35em solid rgba(107, 202, 186, 0.5);
    content: "";
    left: 0;
    z-index: -1;
    /* margin-top: calc(0.125em / 2 * -1); */
    position: absolute;
    right: 0;
    /* top: 50%; */
    bottom: 2px;
  }

  .why-invisible-aligners-container {
    grid-column: 2/4;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* column-gap: 8em; */
  }

  .why-invisible-image {
    padding: 1em 4.5em 2em;
  }

  .why-invisible-title {
    @include SubTitles();
    font-size: 1.1em;
    font-weight: bold;
  }

  .why-invisible-description {
    @include Body();
    font-size: 1em;
  }

  /* Location Style Page */

  .locations-page-container {
    grid-column: 2/4;
  }

  .location-parent-container {
    display: grid;
    grid-template-columns: 1fr;
    padding: 1em;
    grid-gap: 1em;
  }

  .child-location {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .location-text {
    text-align: start;
    padding-inline-start: 1em;
    font-size: 1.1em;
  }

  .clinic-name {
    font-weight: bold;
  }

  .locations-book-a-scan-button {
    display: none;
  }

  /* financial Style Page */

  .payment-steps-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1em;
    padding: 1em;
  }
  .payment-step-card {
    text-align: start;
    display: grid;
    grid-gap: 0.5em;
  }
  .step-text-card {
    text-align: start;
    display: grid;
    grid-gap: 0.5em;
  }
  .card-title {
    font-size: 1.3125em;
    font-weight: bold;
  }
  .card-info {
    display: none;
  }

  .payment-plan-button {
    display: grid;
    grid-template-columns: 3fr 1fr;
    text-decoration: none;
    color: #6bcaba;
    font-weight: bold;
    font-size: 1.3125em;
    text-align: start;
  }
  /* .total-payment-mobile-screen {
  } */
  .section-title-modal {
    display: grid;
    grid-template-columns: 1fr 10px 10px;
    text-align: start;
    font-size: 1.3125em;
  }
  .close-payment-modal {
    font-size: 1.5em;
    text-align: start;
  }
  .payment-includes {
    text-align: start;
  }

  .payment-include-image > img {
    display: none;
  }
  .payment-includes ul {
    list-style: none;
  }

  .payment-includes ul li::before {
    display: inline-block;
    content: "\2022";
    color: #6bcaba;
    font-weight: bold;
    width: 0.5em;
  }

  .payment-plan-info {
    display: grid;
    grid-row-gap: 0.5em;
  }

  .payment-plan-info-mobile {
    display: grid;
    grid-template-columns: 2fr 1fr;
    border: 1px groove #000;
    text-align: start;
  }
  .payment-plan-details-parent {
    text-align: start;
  }

  .payment-plan-details-child {
    display: grid;
    grid-gap: 1em;
    text-align: start;
    grid-template-columns: 2fr 1fr;
    margin: 0;
    padding: 0.3em;
    font-size: 1em;
  }
  .payment-plan-info-mobile-box {
    padding: 3px;
    text-align: start;
    border: 1px groove #000;
  }
  .cash-payment {
    display: grid;
    grid-template-columns: 10px 1fr 1fr 10px;
    padding: 1em;
    background: #b3b3b3;
    color: #fff;
    text-align: start;
    margin-bottom: 10px;
  }
  .p-green {
    color: #6bcaba !important;
    font-weight: bold;
  }
  .p-orange {
    color: #ffb81c !important;
    font-weight: bold;
  }

  /* Home Payment Style */

  .home-payment {
    display: grid;
    grid-gap: 1em;
    grid-column: 2/4;
    font-size: 1em;
  }
  .home-payment-buttons {
    display: grid;
    grid-gap: 1em;
    justify-content: center;
  }
  .home-payment-Link {
    @include SubTitles();
    display: inline-block;
    font-size: 1.125em;
    background: #6bcaba;
    color: #ffffff;
    text-decoration: none;
    padding: 0 1em;
    border: none;
    border-radius: 8px;
    line-height: 50px;
    font-weight: 700;
    height: 50px;
    margin: 0;
    margin-bottom: 0.5em;
    width: 130.37px;
    text-align: center !important;
  }
  /* Home Testimonials Style */

  .testimonial-info {
    @include Body();
    display: grid;
    text-align: center;
    padding: 1em;
    grid-gap: 1em;
  }
  .round-button {
    box-sizing: border-box;
    display: block;
    width: 40px;
    height: 40px;
    line-height: 20px;
    border: 1px solid #fff;
    border-radius: 50%;
    color: #f5f5f5;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 20px;
    font-weight: bold;
    transition: all 0.3s ease;
  }
  .display-desktop {
    display: none;
  }

  .line-through {
    text-decoration: line-through #ffb81c;
  }
  .testimonial-contianer {
    padding: 1em;
    text-align: center;
  }
  .testimonial-button-en {
    @include Body();
    position: absolute;
    left: 20%;
    bottom: 5%;
    text-align: start;
  }

  .testimonial-button-ar {
    @include Body();
    position: absolute;
    right: 20%;
    bottom: 5%;
    text-align: start;
  }

  .testimonial-more-results {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    padding: 1em;
  }
  .testimonial-more-results > span {
    @include SubTitles();
    font-size: 1em;
    font-weight: bold;
  }
  .close-modal-video {
    font-size: 1.5em;
    text-align: end;
  }
  .carousel button {
    width: 50px !important;
    height: 50px;
    top: 40% !important;
    border: none !important;
    color: #fafafa !important;
    background: none !important;
    opacity: 1 !important;
  }

  .carousel button:hover {
    background: none !important;
  }

  .carousel > ul {
    position: relative !important;
    margin: 0 !important;
    bottom: 0 !important;
    text-align: center !important;
    width: 100%;
  }
  .carousel ul > li {
    background: #000 !important;
  }
  /* Home carousel testimonial image Style */
  .carousel-testimonial-image {
    /* background: #f5f5f5;
    padding-top: 0.5em; */
    position: relative;
  }
  .close-modal-image {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    font-size: 3em;
    color: #ffffff;
  }
  .testimonial-carousel-content {
    display: grid;
    justify-content: center;
    padding-top: 0 !important;
    grid-gap: 1em;
  }
  /* Complete care  Style */
  .complete-care-more-personal > p {
    padding: 1em 0em 0em 0em !important;
  }

  .complete-care-more-personal ul {
    list-style: none;
  }

  .complete-care-more-personal ul li::before {
    display: inline-block;
    content: "\2022";
    width: 0.5em;
    color: #6bcaba;
    font-weight: bold;
  }
  .complete-care-more-personal ul li {
    padding-top: 1em;
    font-size: 1em;
    font-weight: bold;
  }
  .complete-care-paragraph-with-title {
    padding: 0.8em 3em;
    text-align: start;
  }
  .complete-care-paragraph-with-title p {
    text-align: start !important;
    hyphens: auto;
    font-size: 1em;
    padding: 1em 0 0 0 !important;
  }
  .complete-care-get-started {
    padding: 1em;
    background: #f6f6f6;
    font-size: 1em;
    margin: 2em 0em;
  }
  .complete-care-get-started a {
    margin-top: 1em;
    font-size: 1em !important;
  }
  .complete-care-icons {
    padding-top: 1em !important;
  }

  /*  Reviews slider Style */
  .testimonials-slider {
    grid-column: 2/4;
    display: flex;
    position: relative;
    overflow-x: scroll;
    padding: 1em 0.25em;
  }
  .testimonials-slider::-webkit-scrollbar {
    display: none;
  }
  .testimonials-image-slider {
    position: relative;
    margin-inline-end: 1em;
  }
  .testimonial-play-button-en {
    @include Body();
    position: absolute;
    bottom: 2em;
    left: 2em;
  }
  .testimonial-play-button-ar {
    @include Body();
    position: absolute;
    bottom: 2em;
    right: 2em;
  }
  /* Sitemap  Style */

  .sitemap-content {
    grid-column: 2/4;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 2em 1em;
    text-align: start;
    justify-content: start;
  }
  .sitemap-content a {
    text-decoration: none;
    color: #000;
  }
  .sitemap-content ul {
    list-style: none;
  }

  .sitemap-content ul li::before {
    display: inline-block;
    content: "\2022";
    width: 0.5em;
    color: #6bcaba;
    font-weight: bold;
  }
  .sitemap-content ul li {
    @include SubTitles();
    padding-top: 6px;
  }

  /*  Veneers - choosing between aligners(OneTwoSmile) and veneers Style */
  .aligners-or-veneers-container {
    grid-column: 2/4;
  }

  .aligners-or-veneers {
    grid-column: 2/4;
    padding: 0 4em;
  }
  .space {
    padding-left: 50px;
  }

  /*  About us - styling */

  .paragraph-with-title ul {
    list-style: none;
  }

  .paragraph-with-title ul li::before {
    display: inline-block;
    content: "\2022";
    width: 0.5em;
    color: #6bcaba;
    text-align: start !important;
  }
  .paragraph-with-title ul li {
    padding-top: 10px;
    font-size: 1em;
    text-align: start;
  }
  .list-item-font-size {
    font-size: 1em;
    font-weight: normal !important;
  }

  /*  Ticker bar - styling */
  .ticker-container {
    display: grid;
    grid-column: 2/4;
    grid-template-columns: repeat(2, 1fr);
    padding: 1em;
  }

  /*  Medical-board - styling */
  .medical-advisory-board-container {
    grid-column: 2/4;
    display: grid;
    padding: 1em;
    justify-content: center;
  }

  .medical-board-child div {
    display: grid;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
  }
  .medical-board-title-font {
    font-size: 1.3125em;
    font-weight: bold;
    padding: 0 1em !important;
  }

  /*  OneTwoSmile plus- styling */

  .smile-plus-ideal-title {
    grid-column: 2/4;
    padding: 1em;
    text-align: start;
  }

  .smile-plus-ideal-images {
    @include SubTitles();
    grid-column: 2/4;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 0 1em;
    grid-gap: 1em;
  }
  .smile-plus-table-container {
    grid-column: 2/4;
    text-align: start;
    display: grid;
  }
  .table-content {
    border-collapse: collapse;
  }
  .table-content,
  td,
  th {
    border: 1px solid black;
    border: 1px solid black;
    padding: 10px;
    margin: 0 1em;
    font-size: 1em;
  }
  .table-content,
  th {
    @include SubTitles();
  }

  .treatment-price-container {
    grid-column: 2/4;
    padding: 0 1em !important;
    text-align: start;
    display: grid;
  }
  .treatment-price {
    font-size: 1em;
  }
  .treatment-price ul {
    list-style: none;
  }

  .treatment-price ul li::before {
    display: inline-block;
    content: "\2022";
    width: 0.5em;
    color: #6bcaba;
  }
  .treatment-price ul li {
    @include SubTitles();
    padding-top: 6px;
  }

  .treatment-price-child {
    padding-inline-start: 50px;
    font-size: 1em !important;
    font-weight: normal !important;
  }
  .treatment-price-child ul {
    list-style: none;
  }

  .treatment-price-child ul li::before {
    display: inline-block;
    content: "\2022";
    width: 0.5em;
    color: #6bcaba;
  }
  .treatment-price-child ul li {
    padding-top: 6px;
  }

  /*  SEO Convert Style */

  .h5 {
    @include SubTitles();
    color: #4b4e56 !important;
    font-size: 1.3em !important;
    font-weight: 700 !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .h3 {
    text-align: start !important;
    padding-inline-start: 1em !important;
  }
  .p {
    padding: 0.3em 0 !important;
    @include Body();
  }

  /*  New Mobile Navbar Styling */
  .navbar-mobile-container-with-banner {
    overflow: hidden;
    background-color: white;
    position: fixed;
    top: 70px;
    width: 100%;
    z-index: 999;
    height: 108px;
  }

  .navbar-mobile-container {
    overflow: hidden;
    background-color: white;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    height: 108px;
  }
  .navbar-first-container {
    display: grid;
    grid-template-columns: 24px 1fr;
    justify-content: space-between;
    background: #ecf0f4;
    padding: 0 25px 0 25px;
  }
  .navbar-mobile-flag {
    text-align: start;
    width: 24px;
    height: 16px;
  }

  .navbar-mobile-flag img {
    border-radius: 40%;
  }

  .navbar-mobile-flag button {
    border: none;
    background: none;
  }
  .navbar-phone {
    text-align: end;
    padding: 2px 0px;
  }
  .navbar-phone a {
    text-decoration: none;
    color: #53565a;
  }
  .navbar-second-container {
    display: grid;
    grid-template-columns: 75px 4fr 1fr;
    /* grid-gap: 1em; */
    padding: 1em 25px;
    overflow: visible;
  }
  .navbar-cta {
    text-align: end;
  }

  .overrideBurgerButton {
    position: fixed;
    width: 25px;
    height: 21px;
    right: 1em;
    top: 120px !important;
  }
  .navbar-logo {
    width: 75px;
    height: 40px;
  }
  .heading {
    margin-top: 30px;
  }
  .ReactModal__Overlay {
    z-index: 999 !important;
    top: 40px !important;
  }
  .homepage_videoWrapper__YpLG3.homepage_active__35-0Y {
    opacity: 1 !important;
    padding: 0px !important;
    margin: 0 0 !important;
    width: 100% !important;
  }
  .homepage_videoReview__19GNH {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .new-homepage-testimonials-container {
    display: flex;
    overflow-x: auto;
    padding: 0;
  }

  .new-homepage-testimonial-square {
    text-align: start;
    min-width: 120px;
    margin: 0.1em;
    padding: 0.1em;
  }

  // .testimonial-content p {
  //   padding: 0;
  // }
  /* .homepage_mobileControls__1ofof {
    z-index: 1 !important;
  } */

  /* Treatment-plan-page- styling */

  .treatment-palne-container {
    display: grid;
    /* grid-column: 2/4; */
    padding: 1em 0em !important;
  }
  .label,
  a .loading-label {
    @include Body();
  }
  .object-container {
    margin: 0.5em 0;
  }
  .Viewer {
    background-color: 0xc0ffff;
    position: relative;
    height: 100%;
    width: 100%;
  }
  #container {
    width: 100%;
    height: 300px;
    position: relative;
    padding-bottom: 10px;
  }
  .setting {
    display: grid;
    grid-template-columns: 50px 50px 50px;
    grid-gap: 1em;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* padding: 20px 0px 0px 0px; */
    width: 100%;
  }
  .position {
    /* top: 10px; */
  }
  .position-item {
    /* position: absolute; */
    width: 50px;
    cursor: pointer;
  }
  .position-item img {
    width: 50px;
  }
  .position-item .label {
    color: grey;
  }
  .position-item.active .label {
    color: rgb(89, 181, 172);
  }
  .front {
    left: calc(50% - 90px);
  }
  .upper {
    left: calc(50% - 25px);
  }
  .lower {
    left: calc(50% + 40px);
  }

  .time {
    position: relative;
  }
  .time a:focus {
    outline: 0;
  }
  .time a {
    padding: 5px 40px;
    font-size: 20px;

    background-color: grey;
    color: white;
    cursor: pointer;
  }
  .time a.active {
    background-color: rgb(89, 181, 172);
  }

  .link {
    width: 60px;
    top: calc(50vh - 30px);
  }
  .link img {
    width: 100%;
  }
  .link.back {
    left: 10%;
  }
  .link.after {
    right: 10%;
  }

  .setting.link:hover {
    cursor: pointer;
  }

  #loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .loading-label label {
    font-size: 20px;
    font-weight: 900;
    /* margin-right: 10px; */
  }

  #loading-bar {
    /* width: 100%;
  height: 1em;
  border-radius: 0.25em;
  background-color: black;
  border: 1px solid grey;
  display: inline-flex; */
  }

  #progress {
    /* background-color: #75b800;
  height: inherit;
  border-radius: inherit;
  width: 5%; */
  }
  .obj-steps-container {
    grid-column: 2/4;
    text-align: start;
    padding: 0 3em !important;
    font-size: 1em;
  }
  .close-3dObject-modal {
    font-size: 1.5em;
    text-align: start;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  #container canvas {
    max-width: 260px !important;
    max-height: 300px;
    vertical-align: text-top;
    object-fit: cover;
  }
  @keyframes ldio-r3rh32ytal {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .ldio-r3rh32ytal div {
    box-sizing: border-box !important;
  }
  .ldio-r3rh32ytal > div {
    position: absolute;
    width: 144px;
    height: 144px;
    top: 28px;
    left: 28px;
    border-radius: 50%;
    border: 16px solid #000;
    border-color: #6bcaba transparent #6bcaba transparent;
    animation: ldio-r3rh32ytal 2s linear infinite;
  }

  .ldio-r3rh32ytal > div:nth-child(2),
  .ldio-r3rh32ytal > div:nth-child(4) {
    width: 108px;
    height: 108px;
    top: 46px;
    left: 46px;
    animation: ldio-r3rh32ytal 2s linear infinite reverse;
  }
  .ldio-r3rh32ytal > div:nth-child(2) {
    border-color: transparent gray transparent gray;
  }
  .ldio-r3rh32ytal > div:nth-child(3) {
    border-color: transparent;
  }
  .ldio-r3rh32ytal > div:nth-child(3) div {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotate(45deg);
  }
  .ldio-r3rh32ytal > div:nth-child(3) div:before,
  .ldio-r3rh32ytal > div:nth-child(3) div:after {
    content: "";
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    top: -16px;
    left: 48px;
    background: #6bcaba;
    border-radius: 50%;
    box-shadow: 0 128px 0 0 #6bcaba;
  }
  .ldio-r3rh32ytal > div:nth-child(3) div:after {
    left: -16px;
    top: 48px;
    box-shadow: 128px 0 0 0 #6bcaba;
  }

  .ldio-r3rh32ytal > div:nth-child(4) {
    border-color: transparent;
  }
  .ldio-r3rh32ytal > div:nth-child(4) div {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotate(45deg);
  }
  .ldio-r3rh32ytal > div:nth-child(4) div:before,
  .ldio-r3rh32ytal > div:nth-child(4) div:after {
    content: "";
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    top: -16px;
    left: 30px;
    background: gray;
    border-radius: 50%;
    box-shadow: 0 92px 0 0 gray;
  }
  .ldio-r3rh32ytal > div:nth-child(4) div:after {
    left: -16px;
    top: 30px;
    box-shadow: 92px 0 0 0 gray;
  }
  .loadingio-spinner-double-ring-owdtzm8lg5m {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
    /* background: #ffffff; */
  }
  .ldio-r3rh32ytal {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-r3rh32ytal div {
    box-sizing: content-box;
  }
  /* generated by https://loading.io/ */
  .homepageCollapse .Collapsible {
    text-align: center !important;
    padding: 0 !important;
    width: none !important;
  }
}
/* Our Doctors Page  Started*/
.our-doctor-container {
  width: 300px;
  display: grid;
  grid-template-columns: auto 2fr auto;
  grid-column: 2/4;
  margin: 0 auto;
  align-content: center;
  align-items: center;
}
.our-doctor-container > div > img {
  width: 50px;
  max-height: 50px;
  float: right;
  /* object-fit: cover; */
  border-radius: 8px 0 0 8px;
}
.callToAction-5 {
  @include SubTitles();
  display: inline-block;
  font-size: 1.125em;
  background: #6bcaba;
  color: #ffffff;
  text-decoration: none;
  padding: 0 1em;
  /* border: none; */
  /* border-radius: 8px; */
  line-height: 50px;
  font-weight: 700;
  width: auto;
  height: 50px;
  margin: 0;
  margin: 0.75em 0;
  border-radius: 0 8px 8px 0;
  border-left: 0.5px solid white;
}

.callToAction-6 {
  @include SubTitles();
  display: inline-block;
  font-size: 0.9em;
  background: #6bcaba;
  color: #ffffff;
  text-decoration: none;
  padding: 0 1em;
  /* border: none;
  border-radius: 8px; */
  line-height: 25px;
  font-weight: 700;
  width: auto;
  height: 50px;
  text-align: center;
  border-radius: 0;
  margin: 0;
  /* margin: 0; */
  /* margin-bottom: 0.5em; */
}
/* Our Doctors Page  Ended*/
