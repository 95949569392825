$Acumin-Pro-Regular: "Open Sans";
$Acumin-Pro-Bold: "Open Sans";

$ITC-Avant-Garde-Gothic-Medium: "ITC Avant Garde Gothic Medium";
$ITC-Avant-Garde-Gothic-LT-Bold: "ITC Avant Garde Gothic LT Bold";
$ITC-Avant-Garde-CE-Gothic-Demi: "ITC Avant Garde CE Gothic Demi";
$ITC-Avant-Garde-CE-Gothic-Demi-Light: "ITC Avant Garde CE Gothic Demi Light";
$ITC-Avant-Garde-Std-Bold: "ITC Avant Garde Std Bold";

$Open-Sans: "Open Sans";
$Open-Sans-Bold: "Open Sans Bold";
$Open-Sans-Regular: "Open Sans Regular";

$DroidNaskhBold: "DroidNaskhBold";
$DroidNaskhRegular: "DroidNaskhRegular";

@font-face {
  font-family: "ITC Avant Garde Gothic Medium";
  font-display: swap;
  src: url("fonts/ITC-avant-garde-gothic/ITC Avant Garde Gothic LT Medium.ttf")
    format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "ITC Avant Garde Gothic LT Bold";
  font-display: swap;
  src: url("fonts/ITC-avant-garde-gothic/ITC Avant Garde Gothic LT Bold.ttf")
    format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "ITC Avant Garde CE Gothic Demi";
  font-display: swap;
  src: url("fonts/ITC-avant-garde-gothic/ITC Avant Garde CE Gothic Demi.ttf")
    format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "ITC Avant Garde CE Gothic Demi Light";
  font-display: swap;
  src: url("fonts/ITC-avant-garde-gothic/ITC Avant Garde Gothic LT Extra Light.ttf")
    format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "ITC Avant Garde Std Bold";
  font-weight: 600;
  font-style: normal;
  font-display: swap; /* Read next point */
  unicode-range: U+000-5FF; /* Download only latin glyphs */
  src: local("ITC Avant Garde Std Bold"),
    url("fonts/ITC-avant-garde-gothic/ITCAvantGardeStd-Bold.woff2")
      format("woff2"),
    url("fonts/ITC-avant-garde-gothic/ITCAvantGardeStd-Bold.woff")
      format("woff"),
    url("fonts/ITC-avant-garde-gothic/ITCAvantGardeStd-Bold.otf")
      format("truetype");
}
@font-face {
  font-family: "Open Sans";
  font-display: swap;
  src: url("fonts/OpenSans/OpenSans.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans Bold";
  font-weight: 600;
  font-style: normal;
  font-display: swap; /* Read next point */
  unicode-range: U+000-5FF; /* Download only latin glyphs */
  src: local("Open Sans Bold"),
    url("fonts/OpenSans/OpenSans-Bold.woff2") format("woff2"),
    url("fonts/OpenSans/OpenSans-Bold.woff") format("woff"),
    url("fonts/OpenSans/OpenSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans Regular";
  font-weight: 600;
  font-style: normal;
  font-display: swap; /* Read next point */
  unicode-range: U+000-5FF; /* Download only latin glyphs */
  src: local("Open Sans Regular"),
    url("fonts/OpenSans/OpenSans-Regular.woff2") format("woff2"),
    url("fonts/OpenSans/OpenSans-Regular.woff") format("woff"),
    url("fonts/OpenSans/OpenSans-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "DroidNaskhBold";
  font-display: swap;
  src: url("fonts/DroidNaskh-Bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "DroidNaskhRegular";
  font-display: swap;
  src: url("fonts/DroidNaskh-Regular.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@mixin MainTitles() {
  font-family: $ITC-Avant-Garde-Std-Bold;
}

@mixin SubTitles() {
  font-family: $Open-Sans-Bold;
}

@mixin Body() {
  font-family: $Open-Sans-Regular;
}
